import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Anchor, Text } from 'grommet';
import { isEmpty } from 'lodash';
import { getExtendedHelpUrls, getRoleBasedContactUrl, getRoleBasedFaqUrl } from 'src/utility/helpMenuUtils';

/**
 * Component displaying a simple line with Help links pointing on faq page and contact form
 * role should only be provided if there's NO authenticated user
 * TODO NO specific speaker user support yet
 * @param {Object} props - Component properties
 * @param {string} [props.role=undefined] - the expected user role when calling the component
 * @returns {React.ReactElement} Help Links component
 */
const HelpLink = ({ role = '' }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state?.auth?.user);
  let { helpFaqUrl, helpSupportUrl } = getExtendedHelpUrls(user);
  if (isEmpty(user) && role) {
    helpSupportUrl = getRoleBasedContactUrl(role);
  }
  if (isEmpty(user) && role) {
    helpFaqUrl = getRoleBasedFaqUrl(role);
  }
  return (
    <Text size="xsmall" color="brand" weight="bold">
      <Anchor href={helpFaqUrl} target="_blank">
        {t('component.helpLink.help.center')}
      </Anchor>
      {' / '}
      <Anchor href={helpSupportUrl} target="_blank">
        {t('component.helpLink.contact.us')}
      </Anchor>
    </Text>
  );
};

export default HelpLink;
