import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { faCircleInfo, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Anchor, Box, Form, FormField, ResponsiveContext, Text } from 'grommet';
import {
  Button,
  HelpLink,
  PasswordReveal,
  PasswordValidator,
  Select,
  T,
  TextInput,
  ToolTip,
  TransEx,
  UserConsent
} from 'src/components';
import { UserRole } from 'src/config';
import { THEME_COLORS } from 'src/config/theme';
import { getUserGenderSelectOptions } from 'src/constants';
import { isRequired, normalizeStringOnBlur } from 'src/utility/formValidation';
import { getRoleBasedContactUrl } from 'src/utility/helpMenuUtils';
import Layout, { FormHead } from '../../features/auth/LayoutAuth';
import { getPractitionerOptions } from '../../features/specialties/specialtiesUtils';

/**
 * renders phone field for practitioner as required
 * + info tooltip icon informing that phone is for Viabeez usage only
 * @param {Object} props - component properties
 * @param {string} props.labelId - form field label
 * @returns {React.Component}
 */
const PractitionerPhoneFieldTip = ({ labelId }) => {
  const { t } = useTranslation();
  return (
    <Box align="center" direction="row">
      <Text size="small">{t(labelId)}</Text>
      <Text size="18px" style={{ lineHeight: '15px' }}>
        *
      </Text>
      <ToolTip content={<TransEx i18nKey="phone.only.viabeez.tip" br />} placement="bottom">
        <Box animation={{ type: 'pulse', duration: 500 }} margin={{ left: 'xsmall' }}>
          <FontAwesomeIcon icon={faCircleInfo} color={THEME_COLORS.brand} style={{ outline: 'none' }} size="1x" />
        </Box>
      </ToolTip>
    </Box>
  );
};

/**
 * Renders the practitioner signup wizard form page
 * @param {Object} props - component properties
 * @returns {React.Component} the practitioner signup wizard form page
 */
const SignupWizardForm = ({
  formik,
  isLoading,
  errorSubmit,
  setErrorSubmit,
  errorEmail,
  setErrorEmail,
  validationSchemas,
  tokenInit = false,
  formDisabled = false,
  isUserBeneficiary = false
}) => {
  const { t } = useTranslation();
  const size = useContext(ResponsiveContext);
  const { handleChange, handleBlur, values, errors, touched, isValid, dirty, setFieldValue, handleSubmit } = formik;
  const pwdRef = useRef(null); // create ref to target password input field
  const pwdConfirmRef = useRef(null); // create ref to target password confirmation input field
  const [userAccept, setUserAccept] = useState(false);
  const specialties = useSelector((state) => {
    if (!isUserBeneficiary) {
      return state?.specialties?.list;
    }
  });

  const specialtyOptions = useMemo(() => {
    if (specialties) {
      const opt = getPractitionerOptions();
      opt.push({
        key: 'Other',
        label: (
          <Text size="16px" color={THEME_COLORS.title} weight={600}>
            {t('common.label.other.practitioner.specialty')}
          </Text>
        )
      });
      return opt;
    }
  }, [t, specialties]);

  // build portal contact form url when requested by anonymous practitioner
  const practitionerContactFormUrl = !isUserBeneficiary ? getRoleBasedContactUrl(UserRole.practitioner) : undefined;

  const handleTyping = useCallback(
    (event) => {
      const inputName = event.target.name;
      setErrorSubmit();
      if (inputName === 'email') {
        setErrorEmail();
      } else if (inputName === 'specialty') {
        if (event.option.key === 'Other') {
          window.location.href = practitionerContactFormUrl;
        }
        setFieldValue('specialty', event.option.key);
        return;
      } else if (inputName === 'gender') {
        setFieldValue('gender', event.option.key);
        return;
      }
      handleChange(event);
    },
    [setErrorSubmit, setFieldValue, setErrorEmail, handleChange, practitionerContactFormUrl]
  );

  return (
    <Layout>
      <FormHead
        title=""
        description={
          isUserBeneficiary ? (
            <Text size="16px" weight="bold" color={THEME_COLORS.text}>
              {t('user.signup.welcome.wizard.intro')}
            </Text>
          ) : (
            <>
              <Text size="16px" weight="bold" color={THEME_COLORS.title}>
                {t('practitioner.signup.welcome.wizard.intro')}
              </Text>
              <br />
              <Text size="14px" color={THEME_COLORS.grey2} style={{ fontStyle: 'italic' }}>
                {'( '}
              </Text>
              <FontAwesomeIcon icon={faTriangleExclamation} color={THEME_COLORS.grey2} size="sm" />
              <Text size="14px" color={THEME_COLORS.grey2} margin={{ left: '5px' }} style={{ fontStyle: 'italic' }}>
                {t('practitioner.signup.welcome.wizard.warning')}
                <Anchor size="14px" href={practitionerContactFormUrl}>
                  {t('common.label.here')}
                </Anchor>
                {' )'}
              </Text>
            </>
          )
        }
      />
      {formDisabled && (
        /* Specific error with sign in redirect link if user opens token activation link more than once */
        <Box align="center" animation={{ type: 'fadeIn', duration: 300 }} pad={{ top: 'xsmall', bottom: 'medium' }}>
          <T textAlign="center" size="15px" weight={600} color="status-critical">
            <Trans
              i18nKey="common.token.already.validated"
              t={t}
              components={{ br: <br />, HomeLink: <Anchor size="15px" href="/signin" /> }}
            />
          </T>
        </Box>
      )}
      {/* Apply opacity to disabled form */}
      <Box style={{ opacity: formDisabled ? 0.4 : 1 }}>
        <Form disabled={formDisabled}>
          <Box
            direction={size === 'small' ? 'column' : 'row'}
            justify="center"
            gap="small"
            pad={{ horizontal: 'medium' }}
            align={size === 'small' ? 'center' : 'start'}
            width={size === 'small' ? 'medium' : 'large'}
          >
            {!isUserBeneficiary && (
              <FormField
                htmlFor="specialty"
                error={touched.specialty && errors.specialty}
                label={t('signup.welcome.wizard.label.i.am')}
                fill
                required={isRequired(validationSchemas, 'specialty')}
              >
                <Select
                  name="specialty"
                  plain
                  placeholder={
                    <T size="16px" color="lightGrey">
                      {t('form.specialty.placeholder')}
                    </T>
                  }
                  options={specialtyOptions}
                  labelKey="label"
                  valueKey={{ key: 'key', reduce: true }}
                  value={values.specialty}
                  onChange={handleTyping}
                  dropProps={{
                    style: {
                      boxShadow: 'rgb(255 255 255) 0px 0px 0px 2px, rgb(0 0 0 / 20%) 0px 0px 2px 3px'
                    }
                  }}
                />
              </FormField>
            )}
          </Box>
          <Box
            direction={size === 'small' ? 'column' : 'row'}
            justify="center"
            gap="small"
            pad={{ horizontal: 'medium' }}
            margin={{ top: 'small' }}
            align={size === 'small' ? 'center' : 'start'}
            width={size !== 'small' ? 'large' : undefined}
          >
            <Box width={size === 'small' ? 'medium' : '250px'}>
              <FormField
                name="gender"
                htmlFor="gender"
                label={t('common.label.gender')}
                error={touched.gender && errors.gender}
                required={isRequired(validationSchemas, 'gender')}
              >
                <Select
                  name="gender"
                  id="gender"
                  plain
                  labelKey="label"
                  valueKey={{ key: 'key', reduce: true }}
                  value={values.gender}
                  options={getUserGenderSelectOptions()}
                  onChange={handleTyping}
                  placeholder={
                    <T size="16px" color="lightGrey">
                      {t('form.gender.placeholder')}
                    </T>
                  }
                  dropProps={{
                    style: {
                      boxShadow: 'rgb(255 255 255) 0px 0px 0px 2px, rgb(0 0 0 / 20%) 0px 0px 2px 3px'
                    }
                  }}
                />
              </FormField>
            </Box>
            <Box width="medium">
              <FormField
                name="firstName"
                htmlFor="firstName"
                label={t('common.label.firstName')}
                error={touched.firstName && errors.firstName}
                required={isRequired(validationSchemas, 'firstName')}
              >
                <TextInput
                  id="firstName"
                  name="firstName"
                  onChange={handleTyping}
                  value={values.firstName}
                  onBlur={handleBlur}
                  placeholder={
                    <T size="16px" color="lightGrey">
                      {t('form.firstName.placeholder')}
                    </T>
                  }
                />
              </FormField>
            </Box>
            <Box width="medium">
              <FormField
                name="lastName"
                htmlFor="lastName"
                label={t('common.label.lastName')}
                error={touched.lastName && errors.lastName}
                required={isRequired(validationSchemas, 'lastName')}
              >
                <TextInput
                  id="lastName"
                  name="lastName"
                  onChange={handleTyping}
                  value={values.lastName}
                  onBlur={handleBlur}
                  placeholder={
                    <T size="16px" color="lightGrey">
                      {t('form.lastName.placeholder')}
                    </T>
                  }
                />
              </FormField>
            </Box>
          </Box>
          <Box
            direction={size === 'small' ? 'column' : 'row'}
            justify="center"
            gap="small"
            pad={{ horizontal: 'medium' }}
            align={size === 'small' ? 'center' : 'start'}
            width={size !== 'small' ? 'large' : undefined}
          >
            <Box width={isUserBeneficiary ? 'large' : 'medium'}>
              <FormField
                pad={false}
                name="email"
                htmlFor="email"
                error={(touched.email && errors.email) || errorEmail}
                label={t('common.label.connection.email')}
                required={isRequired(validationSchemas, 'email')}
              >
                <TextInput
                  disabled={tokenInit}
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleTyping}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder={
                    <T size="16px" color="lightGrey">
                      {t('form.email.common.placeholder')}
                    </T>
                  }
                />
              </FormField>
            </Box>
            {!isUserBeneficiary && (
              <Box width="medium">
                <FormField
                  name="phone"
                  htmlFor="phone"
                  label={<PractitionerPhoneFieldTip labelId="common.label.phone" />}
                  error={touched.phone && errors.phone}
                  required={isRequired(validationSchemas, 'phone') ? { indicator: false } : false}
                >
                  <TextInput
                    textColor="title"
                    id="phone"
                    name="phone"
                    onChange={handleTyping}
                    onBlur={(e) => normalizeStringOnBlur(e, handleBlur, setFieldValue, 'phone')}
                    value={values.phone}
                    placeholder={
                      <T size="16px" color="lightGrey">
                        {t('form.phone.placeholder')}
                      </T>
                    }
                  />
                </FormField>
              </Box>
            )}
          </Box>
          <Box
            direction={size === 'small' ? 'column' : 'row'}
            justify="center"
            gap="small"
            pad={{ horizontal: 'medium' }}
            align={size === 'small' ? 'center' : 'start'}
            width={size !== 'small' ? 'large' : undefined}
          >
            <Box width="medium">
              <FormField
                error={touched.password && errors.password}
                name="password"
                htmlFor="password"
                label={t('form.password.label')}
                required={isRequired(validationSchemas, 'password')}
              >
                <PasswordReveal inputRef={pwdRef}>
                  <TextInput
                    ref={pwdRef}
                    type="password"
                    id="password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleTyping}
                    value={values.password}
                    placeholder={
                      <T size="16px" color="lightGrey">
                        {t('form.create.password.placeholder')}
                      </T>
                    }
                  />
                </PasswordReveal>
              </FormField>
            </Box>
            <Box width="medium">
              <FormField
                error={touched.passwordConfirm && errors.passwordConfirm}
                name="passwordConfirm"
                htmlFor="passwordConfirm"
                label={t('form.confirm.password.label')}
                required={isRequired(validationSchemas, 'passwordConfirm')}
              >
                <PasswordReveal inputRef={pwdConfirmRef}>
                  <TextInput
                    ref={pwdConfirmRef}
                    type="password"
                    id="passwordConfirm"
                    name="passwordConfirm"
                    onBlur={handleBlur}
                    onChange={handleTyping}
                    value={values.passwordConfirm}
                    placeholder={
                      <T size="16px" color="lightGrey">
                        {t('form.confirm.password.placeholder')}
                      </T>
                    }
                  />
                </PasswordReveal>
              </FormField>
            </Box>
          </Box>
          <Box justify="center" margin={{ horizontal: 'medium' }}>
            <PasswordValidator value={values.password} valueAgain={values.passwordConfirm} />
          </Box>
          <Box width={size === 'small' ? 'medium' : 'large'} pad={{ horizontal: 'medium' }}>
            <UserConsent setUserOk={setUserAccept} />
          </Box>
          {errorSubmit && (
            <Box direction="row" justify="center" pad="small">
              <Text textAlign="center" size="small" weight={600} color={THEME_COLORS['status-error']}>
                <Trans i18nKey={errorSubmit} t={t} components={{ br: <br /> }} />
              </Text>
            </Box>
          )}
          <Box align="center" margin={{ top: 'small' }}>
            <Button
              primary
              disabled={!(isValid && dirty) || isLoading || !!errorEmail || !userAccept}
              label={t('signup.welcome.wizard.form.action')}
              size="medium"
              bold
              onClick={handleSubmit}
            />
          </Box>
        </Form>
      </Box>
      <HelpLink role={isUserBeneficiary ? UserRole.employee : UserRole.practitioner} />
    </Layout>
  );
};

export default SignupWizardForm;
