import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faHouse, faSitemap, faUsersGear, faUserShield } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Box, CheckBox, RadioButtonGroup, Select, Text } from 'grommet';
import { StatusModal } from 'src/components';
import { displayUserGender } from 'src/components/Card/cardData';
import { THEME_COLORS } from 'src/config/theme';
import { addCompanySite } from 'src/features/companies/companiesSlice';
import { Enum } from 'src/utility/enum';
import {
  normalizeAddress,
  normalizeEmail,
  normalizeString,
  useFormikErrorsLogger,
  validateAddressGeocoding
} from 'src/utility/formValidation';
import geocoder from 'src/utility/geocoder';
import { canManageSite } from 'src/utility/permissions';
import { getPlaceDetails, usePlace } from 'src/utility/places';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, loader, T } from '..';
import FormBuilder from '../Form/Form';

const SiteAdminType = new Enum(
  { key: 'self', value: '0' }, // new site managed by currently connected admin
  { key: 'other', value: '1' }, // new site will be managed by another existing admin for current site
  { key: 'new', value: '2' } // new site will be managed by new admin to create
);

const MyBox = styled(Box)`
  box-shadow: 0px 4px 10px 5px rgba(233, 233, 233, 0.07);
`;

const InstructionStep1 = ({ company }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box direction="row" align="center">
        <FontAwesomeIcon icon={faSitemap} color={THEME_COLORS.text} size="2x" />
        <T size="16px" margin="small">
          {t('page.site.create.step1.header1')}
          <br />
          {t('page.site.create.step1.header2')}
        </T>
      </Box>
      <Box pad={{ left: 'none', right: 'small', bottom: 'small', top: 'small' }}>
        <Text size="medium" weight={600}>
          {t('page.site.create.step1.admin.type')}
        </Text>
      </Box>
      {/*<Box>
      <T size="14px" margin="small" textAlign="center">
        Pour information, le nouveau site va hériter de la formule d'abonnement
        du site actuel qui est :<br />
      </T>
      <T
        size="16px"
        margin="none"
        textAlign="center"
        weight={700}
        color="brand">
        {COMPANY_SUBSCRIPTION_LABELS[company.subscriptionType]}
      </T>
      <T size="14px" margin="small" textAlign="center">
        Si vous souhaitez modifier l'abonnement du nouveau site, merci de
        contacter le service client <b>Viabeez</b>.
      </T>
    </Box>*/}
    </>
  );
};

const InstructionStep2 = () => {
  const { t } = useTranslation();
  return (
    <Box direction="row" align="center">
      <FontAwesomeIcon icon={faHouse} color={THEME_COLORS.text} size="2x" />
      <T size="16px" margin="small">
        {t('page.site.create.step2.header1')}
      </T>
    </Box>
  );
};

const InstructionStep3 = () => {
  const { t } = useTranslation();
  return (
    <Box direction="row" align="center">
      <FontAwesomeIcon icon={faUserShield} color={THEME_COLORS.text} size="2x" />
      <T size="16px" margin="small">
        {t('page.site.create.step3.header1')}
      </T>
    </Box>
  );
};

const InstructionStep4 = () => {
  const { t } = useTranslation();
  return (
    <Box direction="row" align="center">
      <FontAwesomeIcon icon={faUsersGear} color={THEME_COLORS.text} size="2x" />
      <Box direction="column" margin="small">
        <T size="16px">{t('page.site.create.step4.header1')}</T>
        <T size="13px">{t('page.site.create.step4.site.manager.only')}</T>
      </Box>
    </Box>
  );
};

const SiteCard = ({ handleExit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [step, setStep] = useState(0);
  const [siteAdmin, setSiteAdmin] = useState(SiteAdminType.self);
  const [enableSitesChecked, setEnableSites] = useState(false);
  const [useParentSiret, setUseParentSiret] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState('');
  const [errorMail, setErrorMail] = useState();
  const [feedback, setFeedback] = useState({ status: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const company = useSelector((state) => state?.auth?.user?.company);
  const currentUser = useSelector((state) => state.auth?.user);
  const otherAdmins = useSelector((state) => {
    let users = state?.users?.list || [];
    // get all users member of current site/company that have administrator role
    // and site mgt permission
    // this includes the delegated admin(s) if any and excludes self (currently connected admin)
    return users.filter((user) => canManageSite(user) && currentUser._id !== user._id);
  });

  const siteAdminOptions = [
    {
      label: (
        <T color="title" size="14px">
          {t('page.site.create.admin.self')}
        </T>
      ),
      value: SiteAdminType.self
    },
    {
      disabled: !otherAdmins.length,
      label: (
        <T color="title" size="14px">
          {t('page.site.create.admin.other')}
        </T>
      ),
      value: SiteAdminType.other
    },
    {
      label: (
        <T color="title" size="14px">
          {t('page.site.create.admin.new')}
        </T>
      ),
      value: SiteAdminType.new
    }
  ];

  const validationSchemas = [
    Yup.object({
      enableSites: Yup.boolean().required()
    }),
    Yup.object({
      siteName: Yup.string().required(),
      sameSiret: Yup.boolean().required(),
      siret: Yup.string().checkSiret().required(),
      street: Yup.string().required(),
      zipcode: Yup.string().required().checkFrenchZipCode(),
      city: Yup.string().required()
    }),
    Yup.object({
      firstName: Yup.string().required().checkName(),
      lastName: Yup.string().required().checkName(),
      email: Yup.string().required().checkEmail()
    }),
    Yup.object({
      siteAdminId: Yup.string().required()
    })
  ];

  const formik = useFormik({
    initialValues: {
      siteAdminId: siteAdmin === SiteAdminType.self ? currentUser._id : '',
      enableSites: false,
      sameSiret: false,
      siteName: '',
      siret: '',
      street: '',
      zipcode: '',
      city: '',
      firstName: '',
      lastName: '',
      email: ''
    },
    validationSchema: validationSchemas[step],
    // Submit form data to backend
    onSubmit: async ({
      siteAdminId,
      enableSites,
      sameSiret,
      firstName,
      lastName,
      email,
      siteName,
      street,
      city,
      zipcode,
      siret
    }) => {
      setIsLoading(true);
      setFeedback({ status: '', message: '' });
      let formData = {
        siteAdminId,
        firstName: normalizeString(firstName),
        lastName: normalizeString(lastName),
        email: normalizeEmail(email),
        sameSiret,
        company: {
          enableSites,
          name: normalizeString(siteName),
          siret: normalizeString(siret.split(' ').join('')),
          address: {
            street: normalizeAddress(street),
            zipcode: normalizeString(zipcode),
            city: normalizeString(city)
          }
        }
      };

      // add lat and lng
      let doSubmit = true;
      let res = await geocoder(street, zipcode, city);
      const checkData = await validateAddressGeocoding(res, setFieldValue, setFieldTouched);
      const { valid, showError } = checkData;
      if (!valid) {
        doSubmit = false;
        if (showError) {
          setFeedback({
            status: 'error',
            message: t('form.street.check.error.label')
          });
        }
        setIsLoading(false);
      } else {
        const { lat, lng } = res;
        formData.company.address.coordinates = { lat, lng };
        console.log('address data=', JSON.stringify(formData.company.address));
      }

      if (doSubmit) {
        // call API
        let message;
        let { status, data } = await dispatch(addCompanySite(formData));
        setIsLoading(false);
        if (status === 'success') {
          message = `${t('page.site.create.new.site.added', { name: siteName })}\n`;
          switch (siteAdmin) {
            case SiteAdminType.new:
              message += t('page.site.create.new.site.new.admin');
              break;
            case SiteAdminType.other:
              message += t('page.site.create.new.site.existing.admin', { name: selectedAdmin });
              break;
            default:
              message += t('page.site.create.new.site.current.admin');
          }
          setFeedback({ status, message });
          setShowModal(true);
        } else {
          message = t('page.site.create.new.site.failed');
          if (data.message === 'company_already_exists') {
            message += ` ${t('page.site.create.siret.exists.error')}`;
          } else if (data.message === 'email_already_used') {
            message += ` ${t('page.site.create.email.exists.error')}`;
          }
          setFeedback({ status: 'error', message });
        }
      }
    }
  });

  const { validateForm, setFieldValue, setFieldTouched, handleChange, handleSubmit, isValid, dirty, errors } = formik;

  useFormikErrorsLogger(errors);

  useEffect(() => {
    validateForm();
  }, [step, validateForm]);

  const { suggestions, setValue: setPlaceValue } = usePlace();

  const handleStreetSelect = async (e) => {
    let suggestion = e?.suggestion;
    let { street, city, zipcode } = await getPlaceDetails({
      place_id: suggestion?.value
    });
    setFeedback({ status: '', message: '' });
    setFieldValue('street', normalizeAddress(street));
    setFieldValue('city', city);
    setFieldValue('zipcode', zipcode);
  };

  const handleStreetInput = (e) => {
    setFeedback({ status: '', message: '' });
    setPlaceValue(e.target.value);
    setFieldValue('street', e.target.value);
  };

  const formStep = [
    // Step 1 : define who will manage & if new site can create sites
    // Options : Abort or go to step 1
    {
      inputs: [
        {
          type: () => (
            <Box margin={{ left: 'medium' }}>
              <RadioButtonGroup
                name="adminChoice"
                options={siteAdminOptions}
                value={siteAdmin}
                onChange={(event) => {
                  const val = event.target.value;
                  setSiteAdmin(val);
                  if (val === SiteAdminType.self) {
                    setFieldValue('siteAdminId', currentUser._id);
                  } else {
                    setFieldValue('siteAdminId', '');
                  }
                }}
              />
            </Box>
          )
        },
        {
          type: () => (
            <Box>
              <CheckBox
                pad={{ top: 'medium', bottom: 'small' }}
                checked={enableSitesChecked}
                label={
                  <T color="title" margin="5px" size="14px">
                    {t('page.site.create.allow.new.sites.option')}
                  </T>
                }
                onChange={(event) => {
                  setEnableSites(event.target.checked);
                  setFieldValue('enableSites', event.target.checked);
                }}
              />
            </Box>
          )
        }
      ],
      footer: () => (
        <Box align="center" margin={{ top: 'medium' }} direction="row" justify="center" gap="medium">
          <Box width="150px">
            <Button fill label={t('common.button.cancel')} size="medium" bold onClick={() => handleExit()} />
          </Box>
          <Box width="150px">
            <Button primary label={t('common.button.next')} size="medium" bold onClick={() => setStep(1)} />
          </Box>
        </Box>
      )
    },
    // Step 2 : define new site data (name, post address, SIRET)
    // Options : Abort, go back to step 0 or either:
    // - end process (submit) if current user will manage new site
    // - go to step 2 if new admin needs to be created
    // - go to step 3 if existing admin must be selected
    {
      inputs: [
        {
          name: 'siteName',
          label: t('page.site.create.site.name')
        },
        {
          type: () => (
            <Box>
              <CheckBox
                pad={{ top: 'small', bottom: 'none' }}
                checked={useParentSiret}
                label={
                  <T color="text" margin="5px" size="14px">
                    {t('page.site.create.same.siret.option')}
                  </T>
                }
                onChange={(event) => {
                  const { company } = currentUser;
                  const { siret } = company ?? {};
                  setUseParentSiret(event.target.checked);
                  setFieldValue('sameSiret', event.target.checked);
                  if (event.target.checked) {
                    setFieldValue('siret', siret ?? '');
                  } else {
                    setFieldValue('siret', '');
                  }
                  if (inputRef && inputRef.current) {
                    inputRef.current.disabled = event.target.checked;
                    inputRef.current.style.color = event.target.checked ? THEME_COLORS.text : THEME_COLORS.title;
                  }
                }}
              />
            </Box>
          )
        },
        {
          name: 'siret',
          label: t('common.label.siret'),
          placeholder: t('form.siret.placeholder'),
          ref: inputRef
        },
        {
          name: 'street',
          label: t('common.label.short.postal.address'),
          placeholder: t('form.street.placeholder'),
          suggestions: suggestions,
          onSuggestionSelect: handleStreetSelect,
          handleChange: handleStreetInput
        },
        {
          name: 'zipcode',
          label: t('common.label.zipcode'),
          placeholder: t('form.zipcode.placeholder')
        },
        {
          name: 'city',
          label: t('common.label.city'),
          placeholder: t('form.city.placeholder')
        }
      ],
      footer: () => (
        <Box fill align="center" direction="row" justify="center" gap="medium" margin={{ top: 'medium' }}>
          <Box width="150px">
            <Button
              fill
              label={t('common.button.cancel')}
              disabled={isLoading}
              size="medium"
              bold
              onClick={() => handleExit()}
            />
          </Box>
          <Box width="150px">
            <Button
              fill
              label={t('common.button.previous')}
              disabled={isLoading}
              bold
              size="medium"
              onClick={() => {
                setStep(0);
              }}
            />
          </Box>
          <Box width="150px">
            <Button
              primary
              fill
              label={siteAdmin !== SiteAdminType.self ? t('common.button.next') : t('common.button.validate')}
              size="medium"
              bold
              disabled={!(isValid && dirty) || isLoading}
              onClick={() => {
                switch (siteAdmin) {
                  case SiteAdminType.other:
                    setStep(3);
                    break;
                  case SiteAdminType.new:
                    setStep(2);
                    break;
                  default:
                    handleSubmit();
                }
              }}
            />
          </Box>
        </Box>
      )
    },
    // Step 3 : define new site admin data (name, email, ...)
    // Options : Abort, go back to step 1 or end process (submit):
    {
      inputs: [
        {
          name: 'firstName',
          label: t('common.label.firstName'),
          placeholder: t('form.firstName.placeholder')
        },
        {
          name: 'lastName',
          label: t('common.label.lastName'),
          placeholder: t('form.lastName.placeholder')
        },
        {
          name: 'email',
          label: t('common.label.connection.email'),
          placeholder: t('form.email.common.placeholder'),
          handleChange: (e) => {
            setErrorMail(null);
            handleChange(e);
          },
          error: errorMail
        }
      ],
      footer: () => (
        <Box fill align="center" direction="row" justify="center" gap="medium" margin={{ top: 'medium' }}>
          <Box width="150px">
            <Button
              fill
              label={t('common.button.cancel')}
              disabled={isLoading}
              size="medium"
              bold
              onClick={() => handleExit()}
            />
          </Box>
          <Box width="150px">
            <Button
              fill
              label={t('common.button.previous')}
              disabled={isLoading}
              bold
              size="medium"
              onClick={() => {
                setStep(1);
              }}
            />
          </Box>
          <Box width="150px">
            <Button
              primary
              fill
              disabled={!(isValid && dirty) || isLoading}
              label={t('common.button.validate')}
              size="medium"
              bold
              onClick={handleSubmit}
            />
          </Box>
        </Box>
      )
    },
    // Step 4 : select existing admin for new site admin
    // Options : Abort, go back to step 1 or end process (submit):
    {
      inputs: [
        {
          type: () => (
            <Box width="450px" height="small">
              <Select
                size="15px"
                dropHeight="small"
                margin="small"
                name="siteAdminId"
                label=""
                labelKey="label"
                options={otherAdmins.map((admin) => {
                  const gender = displayUserGender(admin?.gender);
                  return {
                    key: admin._id,
                    label: `${gender !== '' ? gender + ' ' : gender}${admin.firstName} ${admin.lastName}`
                  };
                })}
                placeholder={t('page.site.create.admins.select.placeholder')}
                onChange={({ option }) => {
                  setFieldValue('siteAdminId', option.key);
                  setSelectedAdmin(option.label);
                }}
              />
            </Box>
          )
        }
      ],
      footer: () => (
        <Box fill align="center" direction="row" justify="center" gap="medium" margin={{ top: 'medium' }}>
          <Box width="150px">
            <Button
              fill
              label={t('common.button.cancel')}
              disabled={isLoading}
              size="medium"
              bold
              onClick={() => handleExit()}
            />
          </Box>
          <Box width="150px">
            <Button
              fill
              label={t('common.button.previous')}
              disabled={isLoading}
              bold
              size="medium"
              onClick={() => {
                setStep(1);
              }}
            />
          </Box>
          <Box width="150px">
            <Button
              primary
              fill
              disabled={!(isValid && dirty) || isLoading}
              label={t('common.button.validate')}
              size="medium"
              bold
              onClick={handleSubmit}
            />
          </Box>
        </Box>
      )
    }
  ];

  console.log('valid, dirty errors=', isValid, dirty, errors);

  return (
    <>
      <StatusModal
        show={showModal}
        setShow={setShowModal}
        title={[t('page.site.create.title')]}
        feedback={feedback}
        onSuccess={handleExit}
      />
      <MyBox pad="large" background="white" round="4px" width="large">
        {step === 0 && <InstructionStep1 company={company} />}
        {step === 1 && <InstructionStep2 />}
        {step === 2 && <InstructionStep3 />}
        {step === 3 && <InstructionStep4 />}
        <FormBuilder formContent={formStep[step]} formik={formik} validationSchemas={validationSchemas[step]} />

        {isLoading ? (
          <Box fill align="center">
            {loader()}
          </Box>
        ) : (
          ''
        )}
        {feedback.status === 'error' && (
          <Box
            align="center"
            margin={{ top: 'small' }}
            animation={{
              type: 'fadeIn',
              duration: 300
            }}
          >
            <Text size="small" textAlign="center" color="status-critical">
              {feedback.message}
            </Text>
          </Box>
        )}
      </MyBox>
    </>
  );
};

export default SiteCard;
