import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, ResponsiveContext, Text } from 'grommet';
import { t } from 'i18next';
import { UserRole } from 'src/config';
import { eventHasFeedback, fetchEventFeedback, getEventCardBackground } from 'src/utility/eventUtils';
import { getEventPractitioner, getSpecialtyLabel } from 'src/utility/practitionerUtils';
import { isCompanyAdminRole } from 'src/utility/userUtils';
import ShadowGrid from '../List/ShadowGrid';
import EmailLink from '../Misc/EmailLink';
import PractitionerProfileModal from '../Modal/PractitionerProfileModal';
import SpeakerProfileModal from '../Modal/SpeakerProfileModal';
import AppointmentsCount from './AppointmentsCount';
import AttendeesCount from './AttendeesCount';
import AvatarWithPlaceholder from './AvatarWithPlaceholder';
import { FeedbackStars, getEventCompanyAvatarSrc, getEventUserAvatarSrc, PractitionerName, toDate } from './cardData';

const getGridDetails = (isSmallScreen, role, isProReferent = false, hasDelegateEvents = false) => {
  const data = {};
  switch (role) {
    case UserRole.adminCompany:
    case UserRole.rootAdmin:
      data.gridAreas = [['type', 'avatar', 'name', 'position', 'date', 'meter', 'feedback']];
      data.gridAreasSmall = [['avatar'], ['name'], ['date'], ['position'], ['meter'], ['feedback']];
      data.gridRowsSmall = ['auto', 'auto', 'auto', 'auto', 'auto'];
      data.gridColumns = ['10px', '80px', 'auto', '25%', '15%', '15%', '15%'];
      break;
    case UserRole.employee:
      data.gridAreas = [['avatar', 'name', 'position', 'date', 'slot']];
      data.gridAreasSmall = [['avatar'], ['name'], ['position'], ['date'], ['slot']];
      data.gridRowsSmall = ['auto', 'auto', 'auto', 'auto'];
      data.gridColumns = ['80px', '30%', '30%', '15%', '15%'];
      break;
    case UserRole.speaker:
      data.gridAreas = [['logo', 'company', 'position', 'date', 'range', 'meter']];
      data.gridAreasSmall = [['logo'], ['company'], ['position'], ['date'], ['range'], ['meter']];
      data.gridColumns = ['80px', '25%', '30%', '150px', '150px', 'auto'];
      data.gridRowsSmall = ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
      break;
    case UserRole.practitioner:
    default:
      if (isProReferent) {
        data.gridAreas = [['logo', 'company', 'date', 'range', 'name', 'meter']];
        data.gridAreasSmall = [['logo'], ['company'], ['date'], ['range'], ['meter'], ['name']];
        data.gridColumns = ['80px', '25%', '150px', '150px', '20%', 'auto'];
        data.gridRowsSmall = ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
      } else {
        data.gridAreas = [['logo', 'company', 'date', 'range', 'meter']];
        data.gridAreasSmall = [['logo'], ['company'], ['date'], ['range'], ['meter']];
        data.gridColumns = ['80px', '25%', '150px', '150px', 'auto'];
        data.gridRowsSmall = ['auto', 'auto', 'auto', 'auto', 'auto'];
      }
      if (hasDelegateEvents) {
        data.gridAreas[0].splice(2, 0, 'position');
        data.gridAreasSmall.splice(2, 0, ['position']);
        data.gridColumns.splice(2, 0, '200px');
        data.gridRowsSmall.splice(2, 0, 'auto');
      }
      break;
  }

  if (isSmallScreen) {
    return { columns: ['100%'], rows: data.gridRowsSmall, areas: data.gridAreasSmall };
  }
  return { columns: data.gridColumns, rows: ['auto'], areas: data.gridAreas };
};

/**
 * displays past events Card UI
 * event details vary based on user role:
 * employee:
 *   practitioner avatar,
 *   civility/firstName/lastName,
 *   position,
 *   event date,
 *   appointment start/end time
 * company admin:
 *   practitioner avatar,
 *   civility/firstName/lastName,
 *   position,
 *   event date,
 *   booking meter if event not empty or full (includes slots count)
 *   feedback rating (if avail)
 * referent practitioner:
 *   company logo / company name
 *   event date,
 *   event hours range,
 *   civility/firstName/lastName,
 *   booking meter if event not empty or full (includes slots count)
 * other type of practitioners:
 *   company logo / company name
 *   event date,
 *   event hours range,
 *   booking meter if event not empty or full (includes slots count)
 * speaker:
 *   company logo / company name
 *   event specialty
 *   event date,
 *   event hours range,
 *   booking meter if event not empty or full
 *
 * @param {Object} props Component props
 * @param {Object} props.event The event to process
 * @param {function} props.onClickEvent callback function to call on card click
 * @param {string} props.role User's role that displays the past event card role
 * @param {boolean} props.isProReferent true if user is a pro referent
 * @param {string} [props.id=''] Id of employee (if user is employee) to get appointment details
 * @returns {Component}
 */

const PastEventCard = ({ event, onClickEvent, role, isProReferent = false, id = '', hasDelegateEvents = false }) => {
  const [slot, setSlot] = useState(); // appointment start/end if card used for employee
  const [feedback, setFeedback] = useState({});
  const isAdminCompany = useMemo(() => isCompanyAdminRole(role), [role]);
  const isEmployee = useMemo(() => role === UserRole.employee, [role]);
  const isSpeaker = useMemo(() => role === UserRole.speaker, [role]);
  const isPartner = useMemo(() => role === UserRole.practitioner || role === UserRole.speaker, [role]);
  const responsiveSize = useContext(ResponsiveContext);
  const isSmallScreen = responsiveSize === 'small';
  const {
    groupSessionEvent,
    appointments = [],
    _id: eventId,
    company,
    specialty,
    dateStart,
    timeEnd,
    timeStart
  } = event;

  useEffect(() => {
    if (id && !groupSessionEvent) {
      const employeeAppointment = appointments.filter((appointment) => appointment.employee === id);
      if (employeeAppointment && employeeAppointment.length) {
        const appointment = employeeAppointment[0];
        setSlot({ start: appointment.start, end: appointment.end });
      }
    }
  }, [appointments, groupSessionEvent, id]);
  useEffect(() => {
    if (isAdminCompany) {
      if (eventHasFeedback(event)) {
        fetchEventFeedback(eventId, setFeedback, 'PastEventCard');
      }
    }
  }, [event, eventId, isAdminCompany]);

  const { columns, rows, areas } = getGridDetails(isSmallScreen, role, isProReferent, hasDelegateEvents);
  const [showProfile, setShowProfile] = useState(false);
  const practitioner = getEventPractitioner(event);
  const handleViewProfile = (e) => {
    e.stopPropagation();
    setShowProfile((prevState) => !prevState);
  };

  const { total } = feedback;

  return (
    <ShadowGrid
      style={{ cursor: isEmployee ? 'default' : 'pointer' }}
      onClick={onClickEvent}
      align="center"
      justify="start"
      columns={columns}
      rows={rows}
      areas={areas}
    >
      {isAdminCompany && (
        <Box
          gridArea="type"
          margin="none"
          width="100%"
          style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}
          background={getEventCardBackground(event)}
          fill="vertical"
          title={groupSessionEvent ? t('event.group.session.tip.label') : ''}
        />
      )}
      {(isAdminCompany || isEmployee) && (
        <>
          <Box
            gridArea="avatar"
            style={{ justifySelf: 'center' }}
            onClick={handleViewProfile}
            title={t('common.partner.display.profile')}
          >
            <AvatarWithPlaceholder avatarSrc={getEventUserAvatarSrc(event)} />
          </Box>
          <Box
            gridArea="name"
            style={isSmallScreen ? { justifySelf: 'center' } : { justifySelf: 'start' }}
            onClick={handleViewProfile}
            title={t('common.partner.display.profile')}
          >
            <PractitionerName event={event} />
            {isEmployee && (
              <EmailLink
                email={practitioner?.email}
                mailToLabelId={groupSessionEvent ? 'send.email.to.speaker.label' : 'send.email.to.practitioner.label'}
              />
            )}
          </Box>
          {groupSessionEvent ? (
            <SpeakerProfileModal speaker={practitioner} show={showProfile} setShow={setShowProfile} />
          ) : (
            <PractitionerProfileModal practitioner={practitioner} show={showProfile} setShow={setShowProfile} />
          )}
        </>
      )}
      {isPartner && (
        <>
          <Box gridArea="logo" style={{ justifySelf: 'center' }}>
            <AvatarWithPlaceholder avatarSrc={getEventCompanyAvatarSrc(event)} isCompany />
          </Box>
          <Box gridArea="company" style={isSmallScreen ? { justifySelf: 'center' } : { justifySelf: 'start' }}>
            <Text color="title" weight={700}>
              {company?.name}
            </Text>
          </Box>
        </>
      )}
      {isProReferent && (
        <Box gridArea="name" style={isSmallScreen ? { justifySelf: 'center' } : { justifySelf: 'end' }}>
          <PractitionerName event={event} nameSize="16px" />
        </Box>
      )}
      {(isEmployee || isAdminCompany || isSpeaker || hasDelegateEvents) && (
        <Box gridArea="position" style={{ justifySelf: 'center' }}>
          <Text textAlign="center" color="brand">
            {getSpecialtyLabel(specialty)}
          </Text>
        </Box>
      )}
      <Box gridArea="date" style={{ justifySelf: 'center' }}>
        <Text color="title">{toDate(dateStart)}</Text>
      </Box>
      {isPartner && (
        <Box gridArea="range" style={{ justifySelf: 'center' }}>
          <Text color="title">
            {timeStart} - {timeEnd}
          </Text>
        </Box>
      )}
      {isEmployee ? (
        <Box gridArea="slot" style={{ justifySelf: 'center' }}>
          <Text color="title">
            {groupSessionEvent ? `${timeStart}-${timeEnd}` : slot && `${slot.start}-${slot.end}`}
          </Text>
        </Box>
      ) : (
        <>
          <Box gridArea="meter" style={{ justifySelf: 'center' }}>
            {groupSessionEvent ? (
              <AttendeesCount event={event} hasBookingText hasBookingMeter />
            ) : (
              <AppointmentsCount event={event} hasBookingMeter hasBookingText />
            )}
          </Box>
          {isAdminCompany && total >= 0 && (
            <Box gridArea="feedback" direction="row" justify="center" width="100%" pad="small">
              <FeedbackStars note={total} />
            </Box>
          )}
        </>
      )}
    </ShadowGrid>
  );
};

export default PastEventCard;
