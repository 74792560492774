import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  faCircleCheck,
  faCircleInfo,
  faEnvelopeCircleCheck,
  faGlobePointer,
  faImagePortrait,
  faUserGear
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, ResponsiveContext, Text, Tip } from 'grommet';
import { capitalize, isEmpty, toUpper } from 'lodash';
import moment from 'moment-timezone';
import { FileUploadKind } from 'src/config';
import { THEME_COLORS } from 'src/config/theme';
import { getUserGender, MIN_DELAY_FOR_BOOKING, USER_GENDER, VALIDATION_STATUS } from 'src/constants';
import { changeView, views } from 'src/features/dashboard/admin/adminDashboardSlice';
import { changeCurrentUser } from 'src/features/users/usersSlice';
import {
  getBeneficiaryEventAppointmentPricingData,
  getEventPractitioner,
  getPractitionerEventAppointmentPricing,
  isEventWithEquipment
} from 'src/utility/practitionerUtils';
import { hasAvatar, isCompanyAdminRole } from 'src/utility/userUtils';
import { useAppointmentsInformations } from '../../features/events/useAppointmentsInformations';
import {
  isGroupSessionEventSpecialty,
  isRemoteSpecialty,
  SPECIALTY_CONSULTATION_TYPE_PHONE,
  SPECIALTY_CONSULTATION_TYPE_VIDEO,
  specialtyGeneratesRevenue
} from '../../features/specialties/specialtiesUtils';
import StarRating from '../Analytics/StarRating';

// place holder value when field not defined

// constants to size Cards UI (boxes and text)
export const CARD_ACTION_TEXT_SIZE = '15px';
export const CARD_ROOM_TEXT_SIZE = '16px';
export const CARD_ACTION_BOX_MARGIN = '5px';
export const CARD_ACTION_BOX_MARGIN_X_2 = '20px';
export const CARD_ACTION_BOX_MARGIN_X_3 = '36px';
export const CARD_PENDING_PRO_TEXT_SIZE = '16px';

/**
 * returns formatted post address
 * @param {Object} address the post address to format
 * @returns {string|undefined} the formatted post address or undefined if no address
 */
export const formatAddress = (address = {}) => {
  let { street = '', city = '', zipcode = '' } = address;
  const addr = `${street} ${zipcode} ${city}`;
  if (addr.trim() === '') {
    return undefined;
  }
  return addr;
};

/**
 * component that displays post address if defined
 * + icon that gives address state:
 *   no address defined
 *   address defined but not lat/lng coordinates
 *   address text + icon that shows lat/lng values on hover
 * @param {Object} props - component properties
 * @param {Object} [props.address={}] - address to display
 * @returns {React.FC}
 */
export const AddressWithCoordinates = ({ address = {} }) => {
  const { t } = useTranslation();
  const postAddress = formatAddress(address) ?? t('common.not.available.label');
  return (
    <Box direction="row" gap="small">
      <Text size="small">{postAddress}</Text>
      <PostalAddressStateIcon address={address} />
    </Box>
  );
};

/**
 * component that displays a globe icon providing post address status
 * option to not show icon if address is valid
 * the icon shows a tooltip on hover
 *   red => no address defined
 *   orange => address defined but not lat/lng coordinates
 *   brand color => address and coordinates defined, tip shows lat/lng values on hover
 * @param {Object} props - component properties
 * @param {Object} [props.address={}] - address to display
 * @param {boolean} [props.showValidState=true] - if false, don't show valid state icon
 * @returns {React.FC}
 */
export const PostalAddressStateIcon = ({ address = {}, showValidState = true }) => {
  const { t } = useTranslation();
  let tipText;
  let iconColor;
  let validAddress = false;
  if (!isEmpty(address)) {
    const { coordinates = {} } = address ?? {};
    if (!isEmpty(coordinates)) {
      const { lat = null, lng = null } = coordinates ?? {};
      if (lat !== null && lng !== null) {
        tipText = `lat : ${lat} - Lng : ${lng}`;
        iconColor = THEME_COLORS.validPostAddress;
        validAddress = true;
      }
    } else {
      tipText = t('user.no.coordinates.label');
      iconColor = THEME_COLORS.noCoordinates;
    }
  } else {
    tipText = t('user.no.address.label');
    iconColor = THEME_COLORS.noPostAddress;
  }

  // don't show valid state icon if not requested
  if (validAddress && !showValidState) {
    return null;
  }

  return (
    <Tip
      content={
        <Text weight={500} size="small" textAlign="center">
          {tipText}
        </Text>
      }
    >
      <FontAwesomeIcon icon={faGlobePointer} color={iconColor} />
    </Tip>
  );
};

export const displayUserGender = (gender = USER_GENDER.OTHER) => {
  if (gender && gender !== USER_GENDER.OTHER) {
    return getUserGender(gender);
  }
  return '';
};

/**
 * returns formatted user full name if avail
 * or partial name in firstName lastName order or
 * placeholder if none defined
 * @param {Object} user the user to get name from
 * @returns {string|undefined} the formatted user name or undefined if no valid user
 */
export const getUserName = (user = {}) => {
  if (user) {
    let { gender = null, firstName = null, lastName = null } = user;
    if (firstName || lastName) {
      let civility = displayUserGender(gender);
      let name = firstName ? `${civility} ${capitalize(firstName)} ` : '';
      name += lastName ? lastName.toUpperCase() : '';
      return name;
    }
  }
  return undefined;
};

/**
 * renders pricing information with tooltip icon when tip is defined
 * @function
 * @param {object} props - component properties
 * @param {string} props.price pricing info to display
 * @param {string} props.tip tooltip message if defined
 * @param {object} props.event - event to display price for
 * @returns {Component}
 */
const AppointmentPriceWithTip = ({ price, tip, event, fromEvent }) => {
  const { t } = useTranslation();
  const { groupSessionEvent = false } = event ?? {};
  const labelId = groupSessionEvent ? 'common.label.price' : 'component.event.card.consultation.price';
  const responsiveSize = useContext(ResponsiveContext);
  return (
    <Box style={{ height: responsiveSize === 'small' ? '50px' : '24px' }}>
      {tip !== '' && responsiveSize !== 'small' ? (
        <Tip
          content={
            <Text weight={500} size="small" textAlign="center">
              {tip}
            </Text>
          }
          dropProps={{ width: '300px' }}
        >
          <Box direction="row" gap="small" align="center">
            <Box direction="row">
              <Text weight={500} size="small">
                {t(labelId)} :{' '}
                <Text weight={800} size="small" color={!fromEvent ? 'red' : ''}>
                  {price}
                </Text>
              </Text>
            </Box>
            <FontAwesomeIcon icon={faCircleInfo} color={THEME_COLORS.brand} />
          </Box>
        </Tip>
      ) : (
        <Text weight={500} size="small">
          {t(labelId)} :{' '}
          <Text weight={800} size="small">
            {price}
          </Text>
        </Text>
      )}
    </Box>
  );
};

/**
 * returns the event appointment pricing info
 * @function
 * @param {Object} event The event to compute pricing info from
 * @returns {Component} the appointment price info display component
 */
export const getAppointmentPrice = (event) => {
  const pricingInfo = getBeneficiaryEventAppointmentPricingData(event);
  if (pricingInfo?.price > 0) {
    return (
      <AppointmentPriceWithTip
        event={event}
        fromEvent={pricingInfo.eventPricing}
        tip={pricingInfo.tip}
        price={`${pricingInfo.price}€`}
      />
    );
  }
  if (pricingInfo?.label) {
    return (
      <AppointmentPriceWithTip
        event={event}
        fromEvent={pricingInfo.eventPricing}
        tip={pricingInfo.tip}
        price={pricingInfo.label}
      />
    );
  }
  // No pricing info
  return <Box style={{ height: '24px' }}></Box>;
};

/**
 * returns the event time slot duration in minutes
 * @function
 * @param {Object} event The event to compute time slot duration from
 * @returns {number} the time slot duration in minutes
 */
export const getAppointmentDuration = (event) => {
  if (event?.slots?.length) {
    let duration = 0;
    const slot = event.slots[0];
    const start = slot.from.split(':'),
      end = slot.to.split(':');
    const startH = parseInt(start[0], 10),
      startM = parseInt(start[1], 10);
    const endH = parseInt(end[0], 10),
      endM = parseInt(end[1], 10);
    // Max duration is 1h45min
    if (endH > startH) {
      duration += 60;
    }
    duration += endM - startM;
    const hours = Math.floor(duration / 60);
    const mins = duration % 60;
    if (hours) {
      return `${hours}h${mins ? (mins < 10 ? '0' + mins : mins) : ''}`;
    } else {
      return `${mins} minutes`;
    }
  }
  return '';
};

/**
 * Format an ISO string date into locale string date
 * @param {string} stringDate - ISO string date
 * @param {object} options - toLocaleDateString options
 * @returns {string} date in desired format with respect to passed options
 */
export const toDate = (stringDate, options = {}) => {
  const date = new Date(stringDate);
  return date.toLocaleDateString('fr-FR', options);
};

/**
 * formats phone number given as a string
 * in chunks of 2 digits concatenated with given separator
 * returns phone number unchanged if not 10 digits long
 * @param {string} phone - phone number to format
 * @param {string} [separator='.'] - separator to use
 * @returns {string} formatted phone number or '' if no phone provided
 */
export const phoneFormat = (phone, separator = '.') => {
  if (phone) {
    if (phone.length !== 10) {
      return phone;
    }
    const chunks = [];
    for (let i = 0, off = 0; i < 5; i++, off += 2) {
      chunks[i] = phone.substring(off, off + 2);
    }
    return chunks.join(separator);
  }
  return '';
};

/**
 * formats & renders phone number with given size & separator
 * or returns Not provided label
 * @component
 * @param {Object} props - Component properties
 * @param {string} props.phone - the phone number to format
 * @param {string} [props.separator="."] - the phone number separator
 * @param {string} [props.size="small"] - the text size
 * @returns {Component} formatted phone number as a Text component
 */
export const PhoneFormatter = ({ phone, separator = '.', size = 'small', ...restProps }) => {
  const { t } = useTranslation();
  let formatted = t('common.not.provided.male.label');
  if (phone && phone !== '') {
    formatted = phoneFormat(phone, separator);
  }
  return (
    <Text size={size} color="brand" {...restProps}>
      {formatted}
    </Text>
  );
};

/**
 * Determines what practitioner phone number should be displayed
 * provider or true practitioner one
 * @component
 * @param {Object} props Component props
 * @param {object} props.event The event to get actual practitioner phone number from
 * @returns {Component} the practitioner phone component to render
 */
export const PractitionerPhone = ({ event }) => {
  const { phone } = getEventPractitioner(event) ?? {};
  return <>{phone ? <PhoneFormatter phone={phone} /> : ''}</>;
};

/**
 * renders event location (room) if defined or warning text
 * on small screen, location text size forced to 'small'
 * @component
 * @param {Object} props - Component props
 * @param {object} props.event - The event to get actual location (room) from
 * @param {string} [props.textColor='white'] - Text font color
 * @param {number} [props.textSize=CARD_ROOM_TEXT_SIZE] - Text font size
 * @param {number} [props.textWeight=500] - Text font weight
 * @returns {Component} Component rendering event location
 */
export const EventLocation = ({ event, textColor = 'white', textSize = CARD_ROOM_TEXT_SIZE, textWeight = 500 }) => {
  const { t } = useTranslation();
  const size = useContext(ResponsiveContext);
  const { location, specialty } = event ?? {};
  const { consultationType } = specialty ?? {};
  let eventLocation = t('component.event.card.room.undefined');
  if (location) {
    if (isRemoteSpecialty(specialty)) {
      let remoteMode = '';
      if (consultationType & SPECIALTY_CONSULTATION_TYPE_PHONE) {
        remoteMode += t('common.specialty.consultationType.phone').toLowerCase();
      }
      if (consultationType & SPECIALTY_CONSULTATION_TYPE_VIDEO) {
        const videoLabel = t('common.specialty.consultationType.video').toLowerCase();
        remoteMode += remoteMode === '' ? videoLabel : `/${videoLabel}`;
      }
      eventLocation = t('common.specialty.remote.event', { remoteMode });
    } else {
      eventLocation = `${t('component.event.card.room')} ${location.toLowerCase().replace('salle ', '')}`;
    }
  }
  return (
    <Text color={textColor} size={size === 'small' ? 'small' : textSize} weight={textWeight}>
      {eventLocation}
    </Text>
  );
};

/**
 * Determines what practitioner name should be displayed
 * if the event was subscribed and practitioner details were entered
 * that are different from practitioner owner account (event provider)
 * (can only be the case if account set with unlimited catchment area)
 * if actual practitioner info were entered they are saved under event provider prop
 * @component
 * @param {Object} event The event to get actual practitioner name
 * @param {string} [nameSize="medium"] The practitioner name text size, defaults to "medium"
 * @param {boolean} [nameOnly=false] returns only practitioner name, defaults to false
 * @param {Object} displayOptions The card display options
 * @param {boolean} displayOptions.showDetails if true display /practitioner details like phone number
 * @param {boolean} displayOptions.showSubscriber if true display the on behalf of event subscriber Name
 * @param {boolean} displayOptions.showSubscriberNetwork if true display the on behalf of event subscriber Network name
 * @param {boolean} displayOptions.subscriberLink if true on behalf of event subscriber Name is clickable
 * @param {number} [nameFontWeight=700] font weight for name display
 * @param {string} [nameFontColor='title'] font color for name display
 *  and displays subscriber details (card)
 * @returns {Component} the practitioner name component to render
 */
export const PractitionerName = ({
  event,
  displayOptions = {},
  nameSize = 'medium',
  nameOnly = false,
  nameFontWeight = 700,
  nameFontColor = 'title'
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { specialty } = event ?? {};
  const groupSessionEvent = isGroupSessionEventSpecialty(specialty);
  const providerData = useMemo(() => event?.provider, [event]);
  const practitionerData = useMemo(() => event?.practitioner, [event]);
  const practitioner = useMemo(() => getEventPractitioner(event), [event]);
  const { firstName, lastName, gender } = practitioner ?? {};
  if (nameOnly) {
    return (
      <Text color={nameFontColor} weight={nameFontWeight} size={nameSize}>
        {practitioner ? (
          <>
            {firstName && lastName
              ? `${displayUserGender(gender)} ${capitalize(firstName)} ${toUpper(lastName)}`
              : `(${t('user.no.name')})`}
          </>
        ) : (
          ''
        )}
      </Text>
    );
  }

  const {
    showDetails = false,
    showSubscriber = false,
    showSubscriberNetwork = false,
    subscriberLink = false
  } = displayOptions;

  const handleViewUser = (id) => {
    dispatch(changeCurrentUser(id));
    // switch view
    dispatch(changeView(views.practitioners.detail));
  };

  const {
    networkName: practitionerNetwork,
    gender: practitionerGender,
    firstName: practitionerFirstName,
    lastName: practitionerLastName,
    phone: practitionerPhone,
    _id: practitionerId
  } = practitionerData ?? {};

  const {
    gender: providerGender,
    firstName: providerFirstName,
    lastName: providerLastName,
    phone: providerPhone
  } = providerData ?? {};

  let subscriberData = '';
  let hasSubscriber = false;
  if (practitionerData) {
    if (showSubscriberNetwork) {
      subscriberData += practitionerNetwork ? practitionerNetwork : '';
    }
    if (showSubscriber) {
      if (subscriberData !== '') {
        subscriberData += ' (';
        hasSubscriber = true;
      }
      subscriberData += `${displayUserGender(practitionerGender)} ${capitalize(practitionerFirstName)} ${toUpper(
        practitionerLastName
      )}${hasSubscriber ? ')' : ''}`;
    }
  }
  return (
    <Text
      color={nameFontColor}
      weight={practitionerData ? nameFontWeight : 400}
      size={practitionerData ? nameSize : 'small'}
    >
      {practitionerData ? (
        <>
          {providerData ? (
            <>
              <Box direction="row">
                {providerFirstName && providerLastName && (
                  <>
                    {`${displayUserGender(providerGender)} ${capitalize(providerFirstName)} ${capitalize(
                      providerLastName
                    )}`}
                    {showDetails && (
                      <Text weight={400} size="small" style={{ lineHeight: '23px' }} margin={{ left: 'medium' }}>
                        {providerPhone ? <PhoneFormatter phone={providerPhone} /> : ''}
                      </Text>
                    )}
                    <br />
                  </>
                )}
              </Box>
              {showSubscriber || showSubscriberNetwork ? (
                <Box direction="row">
                  <Text size="xsmall" color="brand">
                    {t('component.event.card.practitioner.on.behalf')}{' '}
                    {subscriberLink ? (
                      <Button bold onClick={() => handleViewUser(practitionerId)} style={{ fontSize: '16px' }}>
                        {subscriberData}
                      </Button>
                    ) : (
                      subscriberData
                    )}
                  </Text>
                </Box>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              {`${displayUserGender(practitionerGender)} ${capitalize(practitionerFirstName)} ${toUpper(
                practitionerLastName
              )}`}
              {showDetails ? (
                <Text weight={400} size="small" style={{ lineHeight: '23px' }} margin={{ left: 'medium' }}>
                  {practitionerPhone ? <PhoneFormatter phone={practitionerPhone} /> : ''}
                </Text>
              ) : (
                ''
              )}
            </>
          )}
        </>
      ) : groupSessionEvent ? (
        t('events.speaker.pending.label')
      ) : (
        t('event.practitioner.pending.label')
      )}
    </Text>
  );
};

export const FeedbackStars = ({ note }) => (
  <Box direction="row" justify="center">
    <StarRating note={note} starCount={5} color={THEME_COLORS.brand} />
  </Box>
);

/**
 * checks if event start date is today and if yes, if given slot time is now in the past
 * @param {object} event  Event to check
 * @param {object} slot   Time slot to check
 * @returns {boolean} true if event date is today and given time lot now in the past
 * @function
 */
export const isSlotTimeTodayAndPast = (event, slot) => {
  const now = moment().tz('Europe/Paris').utc().format();
  if (moment(now).isSame(event.dateStart, 'day')) {
    // event is today, check slot time...
    const start = slot.from.split(':');
    const timeSlotDate = moment().tz('Europe/Paris');
    timeSlotDate
      .hour(parseInt(start[0], 10))
      .minute(parseInt(start[1], 10) - MIN_DELAY_FOR_BOOKING)
      .utc()
      .format();
    return moment(now).isAfter(timeSlotDate);
  } else {
    return false;
  }
};

/**
 * displays a boolean value as Yes/No text
 * @param {Object} props Component props
 * @param {boolean} props.value value to display
 * @param {string} [props.size='14px'] status text size
 * @param {number} [props.weight=500] status text weight
 * @param {string} [props.margin='none'] status text margins if any
 * @returns {Component} the component that displays boolean value as Yes/No text
 */
export const BooleanParam = ({ value, size = '14px', weight = 500, margin = 'none' }) => {
  const { t } = useTranslation();
  return (
    <>
      {!!value ? (
        <Text weight={weight} color="brand" size={size} margin={margin}>
          {capitalize(t('common.label.yes'))}
        </Text>
      ) : (
        <Text weight={weight} color="orange" size={size} margin={margin}>
          {capitalize(t('common.label.no'))}
        </Text>
      )}
    </>
  );
};

/**
 * displays a boolean value as Yes/No text if provided value defined and based on value truthy state
 * or ? if value prop undefined
 * @param {Object} props Component props
 * @param {boolean} props.value value to display
 * @param {string} [props.size='14px'] status text size
 * @param {number} [props.weight=500] status text weight
 * @param {string} [props.margin='none'] status text margins if any
 * @returns {Component} the component that displays value as Yes/No/? text
 */
export const BooleanUndefinedParam = ({ value, size = '14px', weight = 500, margin = 'none' }) => (
  <>
    {value === undefined ? (
      <Text weight={700} size={size} margin={margin}>
        &nbsp;&nbsp;?&nbsp;&nbsp;
      </Text>
    ) : (
      <BooleanParam value={value} size={size} weight={weight} margin={margin} />
    )}
  </>
);

/**
 * displays practitioner account validation status based on
 * isEmailValid and onBoardingCompleted properties and avatar presence
 * @param {Object} props Component props
 * @param {Object} props.user user to display status for
 * @param {boolean} [props.isAdmin=false] true if called for VB admin UI
 * @returns {Component} the component that displays practitioner user status
 */
export const PractitionerValidationStatus = ({ user, isAdmin = false }) => {
  const { isEmailValid, onBoardingCompleted, status, address } = user ?? {};
  const { t } = useTranslation();
  // if VB admin and practitioner has a profile avatar, show icon to indicate status
  let showAvatarDefined = isAdmin && hasAvatar(user);
  let accountStatus =
    status === VALIDATION_STATUS.activated ? t('common.account.valid.status') : t('common.account.valid.ready.status');
  let color = THEME_COLORS['status-ok'];
  let icon = faCircleCheck;
  if (!isEmailValid) {
    accountStatus = t('common.account.status.email.not.validated');
    color = THEME_COLORS['status-error'];
    icon = faEnvelopeCircleCheck;
  } else if (!onBoardingCompleted) {
    accountStatus = t('common.account.status.onboarding.pending');
    color = THEME_COLORS['status-error'];
    icon = faUserGear;
  }
  return (
    <Box align="center" direction="row" gap="xsmall">
      {showAvatarDefined && (
        <FontAwesomeIcon
          icon={faImagePortrait}
          color={THEME_COLORS['status-ok']}
          title={t('practitioner.avatar.defined')}
        />
      )}
      <FontAwesomeIcon icon={icon} color={color} title={accountStatus} />
      <PostalAddressStateIcon address={address} showValidState={false} />
    </Box>
  );
};

/**
 * displays speaker account validation status based on
 * isEmailValid property and avatar presence
 * @param {Object} props Component props
 * @param {Object} props.user user to display status for
 * @param {boolean} [props.isAdmin=false] true if called for VB admin UI
 * @returns {Component} the component that displays speaker user status
 */
export const SpeakerValidationStatus = ({ user, isAdmin = false }) => {
  const { isEmailValid, status, address } = user ?? {};
  const { t } = useTranslation();
  // if VB admin and speaker has an avatar defined, show icon to indicate status
  let showAvatarDefined = isAdmin && hasAvatar(user);
  let accountStatus =
    status === VALIDATION_STATUS.activated ? t('common.account.valid.status') : t('common.account.valid.ready.status');
  let color = THEME_COLORS['status-ok'];
  let icon = faCircleCheck;
  if (!isEmailValid) {
    accountStatus = t('common.account.status.email.not.validated');
    color = THEME_COLORS['status-error'];
    icon = faEnvelopeCircleCheck;
  }
  return (
    <Box align="center" direction="row" gap="xsmall">
      {showAvatarDefined && (
        <FontAwesomeIcon icon={faImagePortrait} color={THEME_COLORS['status-ok']} title={t('speaker.avatar.defined')} />
      )}
      <FontAwesomeIcon icon={icon} color={color} title={accountStatus} />
      <PostalAddressStateIcon address={address} showValidState={false} />
    </Box>
  );
};

/**
 * Builds UI to display event registration stats counters
 * either stats on appointments for consultation based events
 * or stats on Attendees for group session events
 * @param {Object} props - the component properties
 * @param {Object} [props.event] - the event to process
 * @param {string} [props.textSize='14px'] - the text size, default is 14px
 * @param {string} [props.textWeight=700] - the text font weight, default is 700
 * @returns {Component} Event registration stats UI component
 */
export const EventRegistrationStats = ({ event, textSize = '14px', textWeight = 700 }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state?.auth?.user);
  const { groupSessionEvent, specialty, appointments = [], attendees = [] } = event ?? {};
  const appointmentPrice = getPractitionerEventAppointmentPricing(event) || 0;

  const { achievedAppointmentsCount, absentAppointmentsCount, formattedRevenue } = useAppointmentsInformations(
    appointments,
    event
  );
  const eventHasRevenueInfo = specialtyGeneratesRevenue(specialty);
  const hasRegistration = groupSessionEvent ? !!attendees.length : !!appointments.length;

  // TODO update when presence data available for group events
  const attended = groupSessionEvent ? attendees.length : achievedAppointmentsCount;
  const unexcused = groupSessionEvent ? 0 : absentAppointmentsCount;
  const revenue = groupSessionEvent ? 0 : formattedRevenue;
  const revenueDetailsId = groupSessionEvent
    ? 'attendance.total.revenue.calculation'
    : 'appointment.total.revenue.calculation';

  return (
    <>
      {hasRegistration && (
        <Box style={{ border: '1px solid', borderRadius: '8px' }} pad="small" gap="small">
          <Text size={textSize} weight={textWeight}>
            {groupSessionEvent ? t('status.event.participant.present') : t('status.event.patient.seen')} : {attended}
          </Text>
          <Text size={textSize} weight={textWeight}>
            {t('status.event.unexcused')} : {unexcused}
          </Text>

          {!isCompanyAdminRole(user) && eventHasRevenueInfo && (
            <Text size={textSize} weight={textWeight}>
              {t('component.event.card.total.revenue', { totalRevenue: revenue })}
              {/* if appointment/presence pricing model, display nb of occurrences and price*/}
              {!isEventWithEquipment(event) && (
                <>
                  {t(revenueDetailsId, {
                    count: attended,
                    appointmentPrice: appointmentPrice === -1 ? 0 : appointmentPrice
                  })}
                </>
              )}
            </Text>
          )}
        </Box>
      )}
    </>
  );
};

export const getEventUserAvatarSrc = (event) => {
  const practitioner = getEventPractitioner(event);
  const { fileUploads: docs, avatar = '' } = practitioner ?? {};
  if (docs) {
    return docs.find((file) => file.kind === FileUploadKind.avatar)?.url;
  }
  return avatar;
};

export const getEventCompanyAvatarSrc = (event) => {
  const docs = event?.company?.fileUploads;
  if (docs) {
    return docs.find((file) => file.kind === FileUploadKind.avatar)?.url;
  }
  return event?.companyAvatar?.url;
};
