import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Image, ResponsiveContext, Text } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';
import logo from '../../assets/images/logo.svg';

const LayoutAuth = (props) => {
  const responsiveSize = useContext(ResponsiveContext);
  return (
    <Box fill>
      <Box
        align="center"
        alignSelf="center"
        gap={props?.gap ? props.gap : 'small'}
        width={responsiveSize === 'small' ? '340px' : 'large'}
        pad={
          responsiveSize === 'small'
            ? { vertical: 'medium', horizontal: 'small' }
            : { vertical: 'large', horizontal: 'medium' }
        }
      >
        {props.children}
      </Box>
    </Box>
  );
};

/**
 * Page layout boilerplate
 * @param {Object} props - Component properties
 * @param {string|null} [props.width=null] - if defined provides content width (if null default width is large)
 * @param {ReactFragment} props.children - wizard step item children components
 * @param {boolean} [props.addLogoHeader=false] - if true, step layout has a logo header at top
 * @returns {React.Component} page layout boilerplate component
 */
export const LayoutSetup = ({ width = null, children, addLogoHeader = false }) => {
  const history = useHistory();
  const responsiveSize = useContext(ResponsiveContext);
  return (
    <>
      {addLogoHeader ? (
        <Box height="100vh">
          <Box fill="horizontal" margin={{ top: 'large' }} align="center" onClick={() => history.push('/')}>
            <Image
              fit="contain"
              src={logo}
              width={responsiveSize === 'small' ? '200px' : '420px'}
              margin={{ bottom: 'small' }}
              onClick={() => history.push('/')}
            />
          </Box>
          <Box fill justify="center" align="center" gap="medium" width={width || 'large'}>
            {children}
          </Box>
        </Box>
      ) : (
        <Box justify="center" align="center" height={responsiveSize === 'small' ? 'auto' : '100vh'}>
          <Box align="center" gap="medium" width={width || 'large'}>
            {children}
          </Box>
        </Box>
      )}
    </>
  );
};

export const FormHead = ({
  title,
  description,
  showCatchPhrase = true,
  titleSize = 'xlarge',
  titleColor = THEME_COLORS.brand
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const responsiveSize = useContext(ResponsiveContext);
  return (
    <Box align="center" width="large">
      <Box align="center" margin={{ bottom: 'medium' }} onClick={() => history.push('/')}>
        <Image
          fit="contain"
          src={logo}
          width={responsiveSize === 'small' ? '200px' : '280px'}
          margin={{ bottom: 'small' }}
          onClick={() => history.push('/')}
        />
        {showCatchPhrase && (
          <Box margin="none">
            <Text textAlign="center" size="medium" weight={700} color="title">
              {t('common.catchphrase')}
            </Text>
          </Box>
        )}
      </Box>
      {title && (
        <Box margin={{ bottom: 'medium', top: 'none' }}>
          <Text weight={700} size={titleSize} color={titleColor} textAlign="center" margin="0">
            {title}
          </Text>
        </Box>
      )}
      {!description ? null : typeof description === 'function' ? (
        description()
      ) : (
        <Box width="90%" margin={{ bottom: 'medium' }}>
          <Text textAlign="center" size="medium">
            {description}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default LayoutAuth;
