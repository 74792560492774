import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components';

/**
 * renders a password change button if user authentication is not SSO based
 * @param {Object} props - component properties
 * @param {Object} props.user - auth user for which button might display
 * @param {function} props.onClick - button click handler
 * @returns {React.FC|null} the password change button component if applicable
 */
const PasswordChangeButton = ({ user, onClick }) => {
  const { t } = useTranslation();
  const { ssoEnabled = false } = user ?? {};
  if (!ssoEnabled) {
    return (
      <Button
        bold
        color="brand"
        border={{ color: 'brand', size: '2px' }}
        label={t('common.button.change.password')}
        onClick={onClick}
      />
    );
  }
  return null;
};

export default PasswordChangeButton;
