import React from 'react';
import { useTranslation } from 'react-i18next';
import { faUserCheck, faUserClock, faUserMinus, faUserSlash } from '@fortawesome/pro-regular-svg-icons';
import { Box, Text } from 'grommet';
import { capitalize, entries } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { THEME_COLORS } from 'src/config/theme';
import { VALIDATION_STATUS } from 'src/constants';
import format from 'src/utility/format';
import { UserRole } from '../config';
import { validateEmail } from './api';

const VALIDATION_TOKEN_LENGTH = 64; // 32 bytes on 2 hex characters

/**
 * Position form option record to be used by form Select
 * @typedef {Object} PositionFormOption
 * @property {string} key
 * @property {string} label
 */

/**
 * Enum of all possible user positions with mapping of old values
 */
const UserPosition = {
  farmer: 'farmer', // Agriculteur
  artisan: 'artisan', // Artisan, commerçant
  executive: 'executive', //Cadre, profession intellectuelle supérieure
  employee: 'employee', //Profession intermédiaire, employé
  laborer: 'laborer', // Agent de production, ouvrier
  retired: 'retired', // Retraité
  student: 'student', // Étudiant
  nonWorking: 'nonWorking', // Sans activité professionnelle
  practitioner: 'practitioner', // Professionnel de santé -> utilisé pour les user pro de santé
  other: 'other'
};
Object.freeze(UserPosition);

/**
 * Object list of all data associated to a position key
 */

const userPositionData = {
  [UserPosition.farmer]: {
    label: 'Agriculteur',
    id: 'user.position.label.farmer'
  },
  [UserPosition.artisan]: {
    label: 'Artisan, commerçant',
    id: 'user.position.label.business.owner'
  },
  [UserPosition.executive]: {
    label: 'Cadre, profession intellectuelle supérieure',
    id: 'user.position.label.executive'
  },
  [UserPosition.employee]: {
    label: 'Profession intermédiaire, employé',
    id: 'user.position.label.employee'
  },
  [UserPosition.laborer]: {
    label: 'Agent de production, ouvrier',
    id: 'user.position.label.laborer'
  },
  [UserPosition.retired]: {
    label: 'Retraité',
    id: 'user.position.label.retiree'
  },
  [UserPosition.student]: {
    label: 'Étudiant',
    id: 'user.position.label.student'
  },
  [UserPosition.nonWorking]: {
    label: 'Sans activité professionnelle',
    id: 'user.position.label.nonworking'
  },
  [UserPosition.practitioner]: {
    label: 'Professionnel de santé',
    id: 'user.position.label.practitioner'
  },
  [UserPosition.other]: {
    label: 'Autre',
    id: 'user.position.label.other'
  }
};
Object.freeze(userPositionData);

/**
 * returns position data for the provided position key
 * @param {string} positionKey A position key
 * @returns {Object} A position data object
 */
function getEmployeePositionData(positionKey) {
  const position = UserPosition[positionKey];
  if (position) {
    return userPositionData[position];
  }
}

/**
 * returns user position defined by key
 * @param {string} positionKey - A position key
 * @returns {string} the matching position
 */
export const getUserPosition = (positionKey) => {
  return UserPosition[positionKey];
};

/**
 * Get the list of all user positions form options to be used by form Select
 * Always put 'other' choice at the end
 * @returns {PositionFormOption[]}  List of all enabled positions form option
 */
export const getUserPositionFormOptions = () => {
  return entries(userPositionData)
    .sort(([positionKey1, positionData1], [, positionData2]) => {
      if (positionKey1 === UserPosition.other) {
        // Always 'other' at the end
        return 1;
      }
      return positionData1 && positionData1.label > positionData2.label ? 1 : -1;
    })
    .reduce((accu, [positionKey, positionData]) => {
      accu.push({ key: positionKey, label: positionData.label });
      return accu;
    }, []);
};

/**
 * Get the position label for the provided position key
 * @param {string} positionKey A position key
 * @returns {string} The label of the matching position if found or position key or ""
 */
export const getUserPositionLabel = (positionKey) => {
  const positionData = getEmployeePositionData(positionKey);
  return positionData?.label || positionKey || '';
};

/**
 * returns user age in years if birth date set otherwise zero
 * @param {Object} user - user to check
 * @returns {number} - user age in years or zero if age can't be computed
 */
export const getUserAge = (user) => {
  return user?.birthDate ? parseInt(moment().diff(user.birthDate, 'years'), 10) : 0;
};

/**
 * displays user email with icon giving email validation status
 * also a tooltip on icon hover
 * green check mark if validated
 * red cross if isEmailvalid property is false
 * @param {Object} props - component properties object
 * @param {Object} props.user - user to check
 * @param {string|null} [props.email=null] - user email if forced and not taken from user object
 * @returns {React.ReactFragment} Component displaying the user email + status
 */
export const UserEmailWithStatus = ({ user, email = null }) => {
  const { t } = useTranslation();
  if (user || email) {
    const { isEmailValid = false, email: userEmail } = user ?? {};
    const mail = email || userEmail;
    const tip = isEmailValid ? t('common.label.validatedEmail') : t('user.status.pending.label');
    return (
      <Box direction="row" align="center" gap="xsmall">
        <Text size="small" title={tip}>
          {isEmailValid ? '✅ ' : '❌ '}
        </Text>
        <Text size="small">{mail}</Text>
      </Box>
    );
  }
  return null;
};

/**
 * displays a link to send validation email to user if needed
 * email sent depends on user status
 * if user email not validated yet => sends an email to validate email address
 * ONLY for practitioners if email is validated but onboarding not completed yet
 * an email to request to complete onboarding will be sent
 * the component renders nothing if user already valid
 * @param {Object} props - component properties object
 * @param {Object} props.user - user to check
 * @param {function} props.onLinkClick - callback to send email to user
 * @returns {React.ReactFragment} Component displaying the link to send invite email to user or nothing
 */
export const UserInviteEmailLink = ({ user, onLinkClick }) => {
  const { t } = useTranslation();
  if (user) {
    const { _id: userId, isEmailValid, onBoardingCompleted = false, role } = user;
    const linkLabelId = isEmailValid ? 'common.label.resend.onboarding.email' : 'common.label.resend.invitation';
    const onBoardingOk = role === UserRole.practitioner ? onBoardingCompleted : true;
    const isValidUser = isEmailValid && onBoardingOk;
    return (
      <>
        {!isValidUser && (
          <Text
            color="accent"
            size="small"
            weight={600}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onLinkClick(userId);
            }}
          >
            {t(linkLabelId)}
          </Text>
        )}
      </>
    );
  }
  return null;
};

export const doEmailValidation = async (
  src,
  validationToken,
  setIsLoading,
  setValue,
  setFeedback,
  t,
  setFormDisabled,
  successCallback
) => {
  try {
    console.log(`${src}:: Validating email token:  ${validationToken} ...`);
    setIsLoading(true);
    const { user } = await validateEmail(validationToken);
    console.log(`${src}:: Token validation success: email: ${user?.email}  role: ${user?.role}`);
    setValue((prevValue) => {
      return { ...prevValue, email: user?.email, role: user?.role };
    });
    if (successCallback) {
      successCallback(true);
    }
    setFeedback({
      status: 'success',
      message: t('page.signIn.success.email.validated')
    });
  } catch (err) {
    let errorMessage = t('page.signIn.error.accountActivation');
    let status = 'error';
    switch (err.message) {
      case 'token_expired':
        errorMessage = t('common.token.expired');
        break;
      case 'not_found':
      case 'invalid_token':
        status = 'invalid_token';
        errorMessage = t('common.token.invalid');
        setFormDisabled(true);
        break;
      default:
    }
    console.error(`${src}:: Token validation error: ${err} message: ${errorMessage} status: ${status}`);
    setFeedback({
      status,
      message: errorMessage
    });
  } finally {
    setIsLoading(false);
  }
};

/**
 * Retrieves validationToken parameter if present from url query string
 * if found also checks that token format is valid
 * @returns {string} validation token or null if not found or invalid format
 */
export const getUrlValidationToken = () => {
  const search = document?.location?.search || '';
  if (search) {
    const { validationToken = null } = queryString.parse(search);
    // check if value found looks like a valid token ie hex string of VALIDATION_TOKEN_LENGTH length
    if (isValidToken(validationToken)) {
      return validationToken;
    }
  }
  return null;
};

/**
 * Checks if validation token provided has valid format
 * ie is hexadecimal string of VALIDATION_TOKEN_LENGTH length
 * @returns {boolean} true if token provided has valid format
 */
export const isValidToken = (token) => {
  const hexRegExp = /^[0-9a-fA-F]+$/;
  if (token) {
    const strValue = String(token);
    if (strValue.length !== VALIDATION_TOKEN_LENGTH) {
      console.log(`${token} has invalid length`);
      return false;
    }
    const valid = hexRegExp.test(strValue);
    console.log(`${token} has ${valid ? 'valid' : 'invalid'} token format.`);
    return valid;
  }
  return false;
};

/**
 * Checks if user account has an avatar document (image) defined
 * @param {Object} user - user to check
 * @returns {boolean} true if user has an avatar image
 */
export const hasAvatar = (user) => {
  const { fileUploads = [] } = user ?? {};
  if (fileUploads.length) {
    const avatar = fileUploads.filter((file) => file.kind === 'avatar');
    return !!avatar.length;
  }
  return false;
};

/**
 * Checks if user has company admin role and manages at least one site
 * ie. has a sites property which is not an empty array
 * @param {Object} user - user to check
 * @returns {boolean} true if user is a company admin and manages site(s)
 */
export const managesSites = (user) => {
  const { sites = [] } = user ?? {};
  return isCompanyAdmin(user) && !!sites.length;
};

/**
 * Enum of the possible results of the health status self assessment
 * @readonly
 * @enum {number}
 */
const SelfAssessmentResultsEnum = {
  robust: 1,
  preFragile: 2,
  fragile: 3
};

/**
 * Object used to apply the correct label to the health status self assessment result
 */
const selfAssessmentResultsLabels = {
  [SelfAssessmentResultsEnum.robust]: 'health.status.self.assessment.result.answer.robust',
  [SelfAssessmentResultsEnum.preFragile]: 'health.status.self.assessment.result.answer.preFragile',
  [SelfAssessmentResultsEnum.fragile]: 'health.status.self.assessment.result.answer.fragile'
};

/**
 * Utility function that returns the correct i18n key based on the result passed as parameter
 * @param {number} labelNumber health status result value
 * @param {function} t i18n translation function
 * @returns {string} i18n key
 */
export const getSelfAssessmentResultLabel = (labelNumber, t) => t(selfAssessmentResultsLabels[labelNumber]);

/**
 * Enum of ths possible encoded values for selfAssessmentDone value returned by the statistics api
 * @readonly
 * @enum {number}
 */
const SelfAssessmentDoneEnum = {
  true: 1,
  false: 2,
  undefined: 3
};

/**
 * Object used to apply the correct label to the health status self assessment done property
 */
const selfAssessmentDoneLabels = {
  [SelfAssessmentDoneEnum.true]: 'common.label.yes',
  [SelfAssessmentDoneEnum.false]: 'common.label.no',
  [SelfAssessmentDoneEnum.undefined]: 'common.label.waiting.for.status'
};

/**
 * Utility function that returns the correct i18n key based on the result passed as parameter
 * @param {number} labelNumber health status done encoded value
 * @param {function} t i18n translation function
 * @returns {string} i18n key
 */
export const getSelfAssessmentDoneLabel = (labelNumber, t) => t(selfAssessmentDoneLabels[labelNumber]);

export const getStatusIcon = (user) => {
  const { status } = user;
  const statusIconTitle = getStatusLabelId(user);
  switch (status) {
    case VALIDATION_STATUS.refused:
      return {
        statusIcon: faUserSlash,
        statusIconColor: THEME_COLORS['status-error'],
        statusIconTitle
      };
    case VALIDATION_STATUS.activated:
      return {
        statusIcon: faUserCheck,
        statusIconColor: THEME_COLORS['status-ok'],
        statusIconTitle
      };
    case VALIDATION_STATUS.unsubscribed:
      return {
        statusIcon: faUserMinus,
        statusIconColor: THEME_COLORS.grey,
        statusIconTitle
      };
    default:
      return {
        statusIcon: faUserClock,
        statusIconColor: THEME_COLORS.title,
        statusIconTitle
      };
  }
};

export const getStatusLabelId = (user) => {
  const { status } = user;
  switch (status) {
    case VALIDATION_STATUS.refused:
      return 'user.status.refused.label';
    case VALIDATION_STATUS.activated:
      return 'user.status.activated.label';
    case VALIDATION_STATUS.unsubscribed:
      return 'user.status.unsubscribed.label';
    default:
      return 'user.status.pending.label';
  }
};

/**
 * Renders beneficiary activity summary
 * if active or not
 * and if active, current count of booking
 * and if available last booking date
 * @param {Object} user - user to process
 * @returns {React.FC} beneficiary activity summary UI component
 */
export const BeneficiaryActivitySummary = ({ user }) => {
  const { t } = useTranslation();
  const { bookingStats = {} } = user ?? {};
  const { bookingCount = 0, lastBookingDate } = bookingStats;
  if (bookingCount > 0) {
    return (
      <>
        <Box>
          <Text size="small">
            {`${capitalize(t('common.label.yes'))} - ${t('page.beneficiary.details.activity.count')} : ${bookingCount}`}
          </Text>
        </Box>
        {lastBookingDate && (
          <Box>
            <Text size="small">{`${t('beneficiary.last.booking.date')}${format(lastBookingDate, 'P')}`}</Text>
          </Box>
        )}
      </>
    );
  }
  return (
    <Text size="small" color={THEME_COLORS['status-error']}>
      {capitalize(t('common.label.no'))}
    </Text>
  );
};

/**
 * Check if beneficiary to consider as active
 * ie made at least one booking (appointment or group session event register)
 * @param {Object} user - user to process
 * @returns {boolean} true if user to consider as active
 */
export const isBeneficiaryActive = (user) => {
  const { bookingStats = {} } = user ?? {};
  const { bookingCount = 0 } = bookingStats;
  return bookingCount > 0;
};

export const companyAdminRoles = () => [UserRole.adminCompany, UserRole.rootAdmin];
export const companyMemberRoles = () => [UserRole.adminCompany, UserRole.rootAdmin, UserRole.employee];

export const isCompanyRootAdmin = (user) => {
  const { role } = user ?? {};
  return role === UserRole.rootAdmin;
};

export const isCompanyAdmin = (user) => {
  const { role } = user ?? {};
  return isCompanyAdminRole(role);
};

export const isCompanyAdminRole = (role) => companyAdminRoles().includes(role);

export const isCompanyBeneficiary = (user) => {
  const { role } = user ?? {};
  return companyMemberRoles().includes(role);
};
