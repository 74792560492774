import React from 'react';
import { faClipboardCheck, faEnvelope, faSquareInfo } from '@fortawesome/pro-regular-svg-icons';
import { Text } from 'grommet';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import MenuItemIcon from '../components/Misc/MenuItemIcon';
import { UserRole } from '../config';
import { getPractitionerTitleLabel, getPractitionerTypeLabel } from '../utility/practitionerUtils';
import { getAppConfig } from './appConfig';

/**
 * @typedef {Object} HelpUrls
 * @property {string} legalUrl The viabeez legal  URL
 * @property {string} helpFaqUrl The viabeez FAQ  URL
 * @property {string} helpSupportUrl The contact  URL
 */

/**
 * Grab all help urls with all necessary query parameters to point to the right page
 * @param {object} user - the user requesting help
 * @returns {HelpUrls} The extended helps urls
 */
export function getExtendedHelpUrls(user) {
  let { legalUrl, helpFaqUrl, helpSupportUrl } = getAppConfig();
  if (!user || isEmpty(user)) {
    return { legalUrl, helpFaqUrl, helpSupportUrl };
  }
  let userRole = '',
    practitionerTypeLabel = '';
  const { role = '', firstName = '', lastName = '', email = '', practitionerType = '', specialty, company = {} } = user;

  switch (role) {
    case UserRole.adminCompany:
    case UserRole.rootAdmin:
      helpFaqUrl += '/?top-category=je-suis-une-entreprise';
      userRole = t('common.user.role.admin.company');
      break;
    case UserRole.employee:
      userRole = t('common.user.role.beneficiary');
      helpFaqUrl += '/?top-category=je-suis-un-e-bénéficiaire';
      break;
    case UserRole.practitioner:
      userRole = t('common.user.role.practitioner');
      practitionerTypeLabel = encodeURIComponent(getPractitionerTypeLabel(practitionerType));
      helpFaqUrl += '/?top-category=je-suis-professionnel-le-de-santé';
      break;
    case UserRole.admin:
      userRole = t('common.user.role.admin');
      helpSupportUrl += `?role=${role}&roleLabel=${encodeURIComponent(userRole)}`;
      return { legalUrl, helpFaqUrl, helpSupportUrl };
    case UserRole.speaker:
      // for now, speakers don't have a dedicated help nor FAQ
      return { legalUrl, helpFaqUrl, helpSupportUrl };
    default:
      console.warn(`getExtendedHelpUrls: invalid user role: ${role}`);
      return { legalUrl, helpFaqUrl, helpSupportUrl };
  }

  helpSupportUrl += `?role=${role}&roleLabel=${encodeURIComponent(userRole)}&firstName=${encodeURIComponent(
    firstName
  )}&lastName=${encodeURIComponent(lastName)}&email=${encodeURIComponent(email)}`;
  helpSupportUrl +=
    role === UserRole.practitioner
      ? `&practitionerType=${practitionerTypeLabel}&position=${encodeURIComponent(
          getPractitionerTitleLabel(specialty)
        )}`
      : `&company-name=${company?.name ? encodeURIComponent(company.name) : ''}`;
  return { legalUrl, helpFaqUrl, helpSupportUrl };
}

/**
 * Get menu items to be used in menus
 * TODO: This should be removed when menus will be unified
 * @param {object} user - the user requesting help
 * @returns {object[]}
 */
export function getHelpMenuItems(user) {
  const { legalUrl, helpFaqUrl, helpSupportUrl } = getExtendedHelpUrls(user);
  return [
    {
      label: (
        <Text size="small" margin={{ top: '2px' }}>
          {t('help.menu.item.faq')}
        </Text>
      ),
      gap: 'small',
      icon: <MenuItemIcon faIcon={faSquareInfo} />,
      onClick: () => {
        window.open(helpFaqUrl, '_blank');
      }
    },
    {
      label: (
        <Text size="small" margin={{ top: '2px' }}>
          {t('help.menu.item.support')}
        </Text>
      ),
      gap: 'small',
      icon: <MenuItemIcon faIcon={faEnvelope} />,
      onClick: () => {
        window.open(helpSupportUrl, '_blank');
      }
    },
    {
      label: (
        <Text size="small" margin={{ top: '2px' }}>
          {t('help.menu.item.legal')}
        </Text>
      ),
      gap: 'small',
      icon: <MenuItemIcon faIcon={faClipboardCheck} />,
      onClick: () => {
        window.open(legalUrl, '_blank');
      }
    }
  ];
}

/**
 * builds contact form url for a user based on his role
 * @param {string} [role=UserRole.adminCompany] - the user role. defaults to company admin
 * @returns {string} the contact url for the user
 */
export const getRoleBasedContactUrl = (role = UserRole.adminCompany) => {
  let { helpSupportUrl } = getAppConfig();
  let userRole = '';
  switch (role) {
    case UserRole.employee:
      userRole = t('common.user.role.beneficiary');
      break;
    case UserRole.practitioner:
      userRole = t('common.user.role.practitioner');
      break;
    case UserRole.adminCompany:
    case UserRole.rootAdmin:
      role = UserRole.adminCompany;
      userRole = t('common.user.role.admin.company');
      break;
    default:
      role = UserRole.speaker;
  }
  if (userRole) {
    helpSupportUrl += `?role=${role}&roleLabel=${encodeURIComponent(userRole)}`;
  }
  return helpSupportUrl;
};

/**
 * builds FAQ url for a user based on his role
 * @param {string} [role=UserRole.adminCompany] - the user role. defaults to company admin
 * @returns {string} the FAQ url for the user
 */
export const getRoleBasedFaqUrl = (role = UserRole.adminCompany) => {
  let { helpFaqUrl } = getAppConfig();
  let faqCategory = '';
  switch (role) {
    case UserRole.employee:
      faqCategory = 'je-suis-un-e-bénéficiaire';
      break;
    case UserRole.practitioner:
      faqCategory = 'je-suis-professionnel-le-de-santé';
      break;
    case UserRole.adminCompany:
    case UserRole.rootAdmin:
      role = UserRole.adminCompany;
      faqCategory = 'je-suis-une-entreprise';
      break;
    default:
    // No FAQ for speaker user yet
  }
  if (faqCategory) {
    helpFaqUrl += `?top-category=${encodeURIComponent(faqCategory)}`;
  }
  return helpFaqUrl;
};
