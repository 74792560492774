import { fromAddress, setKey, setLanguage, setLocationType, setRegion } from 'react-geocode';
import { isEmpty } from 'lodash';
import { normalizeAddress, normalizeString } from 'src/utility/formValidation';

setKey(process.env.REACT_APP_GEOCODE_API_KEY);
setRegion('fr');
setLanguage('fr');
setLocationType('ROOFTOP');

const getAddressComponents = (components = []) => {
  const data = components.reduce((acc, component) => {
    if (component.types.includes('locality')) acc.city = component.long_name;
    else if (component.types.includes('postal_code')) acc.zipcode = component.long_name;
    else if (component.types.includes('route')) acc.street = component.long_name;
    else if (component.types.includes('street_number')) acc.number = component.long_name;
    return acc;
  }, {});
  return {
    street: (data?.number ? `${data.number}${data?.street ? ` ${data.street}` : ''}` : (data?.street ?? '')).trim(),
    zipcode: (data?.zipcode ?? '').trim(),
    city: (data?.city ?? '').trim()
  };
};

/**
 * computes lat and lng from a post address
 * defined by street name, zipcode and city name
 * if not found or error returns undefined
 * if geocoder found lat/lng but for different postal address:
 *  returns found address + lat/lng + match property set to false
 * if provided address found and successful geocoding:
 *  returns lat/lng + match property set to true
 * @param {string} [street=''] - address street name
 * @param {string} [zipcode=''] - address zip code
 * @param {string} [city=''] - address city name
 * @returns {undefined | {lat, lng, match, address}}
 */
const geocoder = async (street = '', zipcode = '', city = '') => {
  try {
    if (street === '' || zipcode === '' || city === '') {
      console.error(`geocoder error: missing address data: '${street}'-'${zipcode}'-'${city}'`);
      return undefined;
    }
    const address = `${normalizeAddress(street)}, ${normalizeString(zipcode)} ${normalizeString(city)}, France`;
    console.log(`geocoder: searching for address: ${address}`);
    const response = await fromAddress(address);
    const { results, status } = response;
    if (status === 'OK') {
      if (results && results?.length) {
        const { geometry = {}, address_components = [] } = results[0] ?? {};
        const { location } = geometry;
        if (!isEmpty(location)) {
          const { lat, lng } = location;
          const addressData = getAddressComponents(address_components);
          const foundAddress = `${addressData.street}, ${addressData.zipcode} ${addressData.city}, France`;
          console.log(`geocoder: computed coordinates lat: ${lat} - lng: ${lng}`);
          const data = { lat, lng, match: true };
          if (address !== foundAddress) {
            console.warn(`geocoder: found result for different address: ${foundAddress}`);
            data.match = false;
          }
          data.address = {
            street: addressData.street,
            zipcode: addressData.zipcode,
            city: addressData.city
          };
          console.log(`geocoder: response data: ${JSON.stringify(data)}`);
          return data;
        }
      }
    } else {
      console.error(`geocoder: fromAddress failed with status: ${status}`);
    }
  } catch (e) {
    console.error(`geocoder: exception message: ${e.message}`);
  }
  return undefined;
};

export default geocoder;
