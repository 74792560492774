import { normalizeColor } from 'grommet/utils';
import { css } from 'styled-components';

export const theme = {
  global: {
    focus: {
      outline: {
        size: '0px'
      },
      border: {
        size: '0px'
      }
    },
    colors: {
      // https://v2.grommet.io/color
      lightBrand: '#aac7fe',
      brand: '#759eeb',
      brand2: '#5179c5',
      brand3: '#3763b7',
      brand4: '#1b4aa2',
      darkBrand: '#0b327c',
      highlight: '#def7e5',
      back: '#F9F9F9',
      title: '#444444',
      text: '#979797',
      accent: '#759eeb',
      'status-ok': '#61EC9F',
      lightGrey: '#C1C1C1',
      'status-critical': '#E27169',
      'status-error': '#E27169',
      'status-warning': '#F4A261',
      warningBackground: '#FCE9D7',
      danger: '#E27169', // TODO: Should be replaced by 'status-error' or 'status-critical' (grommet default)
      red: '#E27169', // TODO: Should be replaced by 'status-error' or 'status-critical' (grommet default)
      grey: '#CCCCCC',
      grey2: '#7B7B7B',
      greyBackground: '#EEEEEE',
      errorBackground: '#fbdddd',
      // colors for event slots state
      available: '#35DBB4',
      reserved: '#759EEB',
      booked: '#A9B3CE',
      white: '#FFF',
      warning: '#9D1919', // color used to emphasize warning conditions
      orange: '#FF5733',
      groupSessionEvent: '#8BC34A',
      registeredEvent: '#78b159',
      reassignEvent: '#dd2e44',
      reassignEventBackground: 'rgba(221, 46, 68, .56)',
      pendingEvent: '#f4900c',
      pendingEventBackground: 'rgba(244, 144, 12, .56)',
      fullEvent: '#9C27B0',
      fullEventBackground: 'rgba(156, 39, 176, .46)',
      emptyComingEvent: '#fadbd8',
      fillingRate: '#55acee',
      canceledEventBackground: '#fef1ef',
      extraBilling: '#ba1e32',
      validPostAddress: '#35DBB4',
      noPostAddress: '#E27169',
      noCoordinates: '#f4900c',
      rootAdmin: '#1abc9c'
    },
    font: {
      family: 'Source Sans Pro',
      size: '18px',
      height: '20px'
    }
  },
  checkBox: {
    border: { color: '#759eeb' },
    hover: { border: { color: '#aac7fe' } },
    check: {
      extend: ({ theme, checked }) => `
          ${checked && `background-color: ${normalizeColor('brand', theme)};`}
          `
    },
    icon: {
      size: '20px',
      extend: 'stroke: white;'
    }
  },
  formField: {
    border: {
      color: 'lightGrey',
      side: 'all'
    },
    round: '4px',
    label: {
      color: 'text',
      size: 'small',
      margin: { left: 'none' },
      requiredIndicator: true
    },
    error: {
      size: 'small'
    }
  },
  dateInput: {
    icon: { size: '0px' }
  },
  calendar: {
    heading: { level: '5' },
    medium: { fontSize: 'xsmall', lineHeight: '1', daySize: '40px' },
    extend: `button:disabled {
        opacity: 1;
        font-weight: 500;
    }`,
    day: {
      extend: (props) => `
            border-radius: 4px;
            background-color: ${props.isSelected ? 'brand' : 'none'};
            color: ${props.isSelected ? 'white' : ''}`
    }
  },
  select: {
    icons: { color: 'accent' },
    options: {
      text: {
        size: '16px'
      }
    }
  },
  button: {
    default: {
      color: 'brand',
      border: {
        color: 'brand',
        radius: '4px',
        width: '1px'
      }
    },
    primary: {
      color: 'white',
      border: {
        radius: '4px'
      },
      background: {
        color: 'brand'
      }
    },
    secondary: {
      color: 'red',
      border: {
        color: 'red',
        radius: '4px',
        width: '1px'
      }
    }
  },
  rangeInput: {
    thumb: {
      color: 'white',
      extend: (props) => css`
        border: 4px #759eeb solid;
      `
    },
    track: {
      lower: {
        color: 'brand',
        opacity: 0.7
      },
      height: '6px',
      color: 'grey',
      extend: (props) => `
          border-radius:4px;
        `
    }
  },
  tip: {
    content: {
      background: {
        opacity: 1,
        color: 'back'
      }
    }
  },
  notification: {
    normal: {
      color: 'brand'
    },
    critical: {
      title: {
        color: '#E27169'
      }
    }
  },
  radioButton: {
    size: '20px',
    icon: {
      size: '20px'
    }
  },
  starRating: {
    color: 'brand'
  }
};

export const THEME_COLORS = theme.global.colors;
