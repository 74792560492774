import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CheckBox, Select, Text } from 'grommet';
import { CompanySitesPath, Spinner } from 'src/components';
import CompanySitesDiagram from 'src/components/Diagram/CompanySitesDiagram';
import CompanySitesMap from 'src/components/Diagram/CompanySitesMap';
import { FileUploadKind } from 'src/config';
import { THEME_COLORS } from 'src/config/theme';
import { getCompanySites } from 'src/features/companies/companiesSlice';

/**
 * renders the sites map legend
 * @returns {Component}
 */
const OrgTreeLegend = () => {
  const { t } = useTranslation();
  return (
    <Box direction="row" pad="none" margin={{ bottom: 'small' }} elevation="small">
      <Box direction="row" pad="5px">
        <Box border={{ color: 'brand', size: '2px', style: 'dashed' }} width="80px" round align="center">
          <Text size="small">{t('page.admin.sites.legend.site')}</Text>
        </Box>
        <Box pad={{ left: '10px' }}>
          <Text size="small" style={{ lineHeight: '25px' }}>
            {t('page.admin.sites.legend.connection.site')}
          </Text>
        </Box>
      </Box>
      <Box direction="row" pad="5px" align="center">
        <FontAwesomeIcon icon={faBuilding} color={THEME_COLORS.brand} size="xl" />
        <Box pad={{ left: '5px' }}>
          <Box border={{ color: 'brand', size: '2px' }} width="40px" round align="center">
            <Text size="small">{t('page.admin.sites.legend.site')}</Text>
          </Box>
        </Box>
        <Box pad={{ left: '10px' }}>
          <Text size="small" style={{ lineHeight: '25px' }}>
            {t('page.admin.sites.legend.self.managed.site')}
          </Text>
        </Box>
      </Box>
      <Box direction="row" pad="5px" align="center">
        <FontAwesomeIcon icon={faBuilding} color={THEME_COLORS.red} size="xl" />
        <Box pad={{ left: '5px' }}>
          <Box border={{ color: THEME_COLORS.red, size: '2px' }} width="40px" round align="center">
            <Text size="small">{t('page.admin.sites.legend.site')}</Text>
          </Box>
        </Box>
        <Box style={{ lineHeight: '25px' }} pad={{ left: '10px' }}>
          <Text size="small" style={{ lineHeight: '25px' }}>
            {t('page.admin.sites.legend.other.admin.site')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

const ComponentToRender = ({ index, user, sites, company, sitesArray, handleClick, adminDashboard = false }) => {
  const companyAvatar = useMemo(
    () => company?.fileUploads?.find((fileUpload) => fileUpload.kind === FileUploadKind.avatar),
    [company]
  );

  switch (index) {
    case 1:
      return <CompanySitesMap company={company} sites={sitesArray} logo={companyAvatar?.url} />;
    case 0:
    default:
      return (
        <CompanySitesDiagram
          user={user}
          sites={sites}
          logo={companyAvatar?.url}
          handleClick={handleClick}
          adminDashboard={adminDashboard}
        />
      );
  }
};

const OrganizationView = ({ user, company, handleClick, adminDashboard = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sites, setSites] = useState();
  const options = [t('page.admin.sites.legend.hierarchy'), t('page.admin.sites.legend.map')];
  const [view, setView] = useState(options[0]);
  const { root, parent } = useSelector((state) => state?.auth?.siteLinks);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSites = async () => {
      setIsLoading(true);
      let { sites } = await dispatch(getCompanySites(company?._id));
      setSites(sites);
      setIsLoading(false);
    };
    fetchSites();
  }, [dispatch, company._id, company, user.sites]);

  const [showLegend, setShowLegend] = useState(false);

  return (
    <>
      <Box fill>
        <Box margin="medium" flex={false}>
          <Box fill>
            <Box>
              <Box pad={{ top: 'small', bottom: 'small' }}>
                <Box direction="row" justify="between">
                  {sites && (
                    <CompanySitesPath
                      root={root}
                      depth={company.depth}
                      parent={parent}
                      name={company.name}
                      sites={sites}
                      textColor="brand"
                      textSize="14px"
                    />
                  )}
                  {view === options[0] && (
                    <CheckBox
                      reverse
                      pad="small"
                      label={<Text size="small">{t('common.legend.label')}</Text>}
                      checked={showLegend}
                      onChange={(event) => setShowLegend(event.target.checked)}
                      toggle
                    />
                  )}
                  <Box>
                    <Select
                      size="small"
                      value={view}
                      options={options}
                      onChange={({ option }) => {
                        setView(option);
                        if (option === options[1]) {
                          setShowLegend(false);
                        }
                      }}
                      disabled={sites && Object.keys(sites).length === 1}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {showLegend && <OrgTreeLegend />}
            <Box align="center" fill>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {sites && Object.keys(sites).length !== 1 ? (
                    <ComponentToRender
                      index={options.indexOf(view)}
                      sites={sites}
                      user={user}
                      company={company}
                      sitesArray={sites}
                      handleClick={handleClick}
                      adminDashboard={adminDashboard}
                    />
                  ) : (
                    <Box fill align="center" pad="medium">
                      <Text size="20px" color="brand" weight={700}>
                        {t('page.admin.sites.no.site.available')}
                      </Text>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OrganizationView;
