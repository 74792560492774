import { UserRole } from '../config';
import api from './api';
import { isCompanyAdmin, isCompanyRootAdmin, managesSites } from './userUtils';

export const BENEFICIARY_PERMISSIONS = {
  none: 0,
  view: 1,
  manage: 2,
  create: 4
};

/**
 * Checks if company is allowed to manage beneficiaries accounts
 * manage = allowed to manage (change config/delete) and/or create beneficiaries
 * @param {Object} company - the company to check
 * @returns {boolean} true if company allowed to manage beneficiaries accounts
 */
export const isAccountsManagedCompany = (company) => {
  if (company) {
    const { options } = company;
    if (options) {
      const { beneficiaryPermissions } = options;
      return !!(
        beneficiaryPermissions & BENEFICIARY_PERMISSIONS.manage ||
        beneficiaryPermissions & BENEFICIARY_PERMISSIONS.create
      );
    }
  }
  return false;
};

export const canCreateBeneficiary = (permissions = BENEFICIARY_PERMISSIONS.none) =>
  !!(permissions & BENEFICIARY_PERMISSIONS.create);
export const canManageBeneficiary = (permissions = BENEFICIARY_PERMISSIONS.none) =>
  !!(permissions & BENEFICIARY_PERMISSIONS.manage);
export const canViewBeneficiary = (permissions = BENEFICIARY_PERMISSIONS.none) =>
  !!(permissions & BENEFICIARY_PERMISSIONS.view);

/**
 * Checks if beneficiary view permission has to be true
 * it is the case when manage and/or create permissions are set as those actions can only be performed from beneficiary view UI
 * @param {number} permissions - permissions value to check
 * @returns {boolean} true if view permission has to be forced to true
 */
export const isViewBeneficiaryMandatory = (permissions = BENEFICIARY_PERMISSIONS.none) =>
  canManageBeneficiary(permissions) || canCreateBeneficiary(permissions);

/**
 * enforces beneficiary view permission to true
 * if manage and/or create permissions are set
 * @param {number} permissions - permissions value to check
 * @returns {boolean} true if view toggle forced or current view permission value
 */
export const enforceViewBeneficiaryRules = (permissions = BENEFICIARY_PERMISSIONS.none) => {
  if (isViewBeneficiaryMandatory(permissions)) {
    return true;
  }
  return canViewBeneficiary(permissions);
};

/**
 * checks if permissions value is compliant with SSO enabled mode
 * if not removes unauthorized permissions
 * if SSO enabled = false, permissions are returned unchanged
 * @param {number} permissions - permissions value
 * @returns {number} SSO compliant permissions value
 */
export const checkSsoBeneficiaryPermissions = (ssoEnabled, permissions) => {
  if (ssoEnabled) {
    if (permissions & BENEFICIARY_PERMISSIONS.create) {
      // create beneficiary not allowed when SSO enabled
      return permissions & ~BENEFICIARY_PERMISSIONS.create;
    }
  }
  return permissions;
};

/**
 * computes a text label that describes what permissions are set in permissions value provided
 * @param {number} permissions - permissions value to get label for
 * @returns {string} text label describing permissions granted
 */
export const beneficiaryPermissionsLabel = (permissions = BENEFICIARY_PERMISSIONS.none) => {
  let strPermissions = '';
  if (permissions) {
    if (permissions & BENEFICIARY_PERMISSIONS.view) {
      strPermissions = 'Visualiser';
    }
    if (permissions & BENEFICIARY_PERMISSIONS.manage) {
      strPermissions += `${strPermissions !== '' ? ' | ' : ''}Gérer`;
    }
    if (permissions & BENEFICIARY_PERMISSIONS.create) {
      strPermissions += `${strPermissions !== '' ? ' | ' : ''}Créer`;
    }
  } else {
    strPermissions = 'Aucune (pas de visualisation/gestion/création)';
  }
  return strPermissions;
};

// Permissions implemented as a bitmask in a unique 32 bits integer for all platform user roles
// each bit is assigned a permission, permission is granted when associated bit is set in integer value
// permissions per role grouped in contiguous bit ranges
// bits [0 … 19]  => company admin	=> 20 permissions available
// bits [20 … 27] => practitioner   => 8 permissions available
// bits [28 … 31] => employee       => 4 permissions available

// Company admin permissions
export const ADMIN_MANAGE_EMPLOYEE_PERMISSION = 1 << 0;
export const ADMIN_MANAGE_EVENT_PERMISSION = 1 << 1;
export const ADMIN_MANAGE_VIEW_NOTIFICATION_PERMISSION = 1 << 2;
export const ADMIN_MANAGE_VIEW_STATS_PERMISSION = 1 << 3;
export const ADMIN_MANAGE_ADMIN_PERMISSION = 1 << 4;
export const ADMIN_MANAGE_SITE_PERMISSION = 1 << 5;
export const ADMIN_MANAGE_SUBSCRIPTION_PERMISSION = 1 << 6;
export const ADMIN_EDIT_COMPANY_INFO_PERMISSION = 1 << 7;
export const ADMIN_MANAGE_BILLING_PERMISSION = 1 << 8;

const PRACTITIONER_MIN_PERM = 1 << 20;
const EMPLOYEE_MIN_PERM = 1 << 28;
const MAX_PERMISSION = 1 << 31; // permissions on 32 bits integer

// default admin permissions
const ADMIN_DEFAULT_PERMISSIONS =
  ADMIN_MANAGE_EMPLOYEE_PERMISSION |
  ADMIN_MANAGE_EVENT_PERMISSION |
  ADMIN_MANAGE_VIEW_NOTIFICATION_PERMISSION |
  ADMIN_MANAGE_VIEW_STATS_PERMISSION;

// ALL possible administrator permissions
const ALL_ADMIN_PERMISSIONS =
  ADMIN_DEFAULT_PERMISSIONS |
  ADMIN_MANAGE_ADMIN_PERMISSION |
  ADMIN_MANAGE_SITE_PERMISSION |
  ADMIN_MANAGE_SUBSCRIPTION_PERMISSION |
  ADMIN_EDIT_COMPANY_INFO_PERMISSION |
  ADMIN_MANAGE_BILLING_PERMISSION;

// Super Admin is granted all Admin permissions
export const SUPER_ADMIN_PERMISSIONS = ALL_ADMIN_PERMISSIONS;

export const COMPANY_MANAGER_PERMISSIONS =
  ADMIN_DEFAULT_PERMISSIONS | ADMIN_MANAGE_ADMIN_PERMISSION | ADMIN_MANAGE_SITE_PERMISSION;

export const COMPANY_OPERATOR_PERMISSIONS = ADMIN_DEFAULT_PERMISSIONS;

// Practitioner permissions
const PRACTITIONER_REGISTER_EVENT_PERMISSION = PRACTITIONER_MIN_PERM;
const PRACTITIONER_MODIFY_PRICE_PERMISSION = 1 << 21;

// Employee permissions
const EMPLOYEE_LEADER_PERMISSION = EMPLOYEE_MIN_PERM;

// ALL possible employee permissions
const ALL_EMPLOYEE_PERMISSIONS = EMPLOYEE_LEADER_PERMISSION;

/**
 * checks a single permission on user
 * @param {Object} user - User to set permission on
 * @param {Number} permission - permission to check
 * @returns {boolean} true if permission is set on user
 */
const isPermissionSet = (user, permission) => Boolean(user?.permissions & permission);

/**
 * checks multiple permissions (multiple bits at same time)
 * @param {Object} user - User to set permission on
 * @param {Number} permissions - permissions to check (multiple bits allowed)
 * @returns {boolean} true if ALL permissions are set on user
 */
const arePermissionsSet = (user, permissions) => (user?.permissions & permissions) === permissions;

// checks user role, true if user has tested role
export const isViabeezAdmin = (user) => user?.role === UserRole.admin;
const isPractitioner = (user) => user?.role === UserRole.practitioner;
const isEmployee = (user) => user?.role === UserRole.employee;
// checks if provided permission is an Admin/Practitioner/Employee permission

/**
 * check if permission is a valid Admin permission
 * as a company admin is also an employee, we need to ignore employee permissions
 * in this test
 * @param {Number} permission - Permission to return label for
 * @returns {boolean}  true if permission is a valid admin permission
 */
export const isCompanyAdminPermission = (permission) => {
  if (permission) {
    // Only keep all possible admin permissions
    const perm = getAdminOnlyPermissions(permission);
    return perm < PRACTITIONER_MIN_PERM;
  }
  return false;
};

const isPractitionerPermission = (permission) => permission >= PRACTITIONER_MIN_PERM && permission < EMPLOYEE_MIN_PERM;
const isEmployeePermission = (permission) => permission >= EMPLOYEE_MIN_PERM && permission <= Math.abs(MAX_PERMISSION);

/**
 * returns permission label (logging purpose)
 * @param {Number} permission - Permission to return label for
 * @returns {string}  the permission label
 */
const getPermissionLabel = (permission) => {
  switch (permission) {
    case ADMIN_MANAGE_EMPLOYEE_PERMISSION:
      return 'Employee Mgt';
    case ADMIN_MANAGE_EVENT_PERMISSION:
      return 'Event Mgt';
    case ADMIN_MANAGE_VIEW_NOTIFICATION_PERMISSION:
      return 'View Notifications';
    case ADMIN_MANAGE_VIEW_STATS_PERMISSION:
      return 'View Stats';
    case ADMIN_MANAGE_ADMIN_PERMISSION:
      return 'Admin Mgt';
    case ADMIN_MANAGE_SITE_PERMISSION:
      return 'Site Mgt';
    case ADMIN_MANAGE_SUBSCRIPTION_PERMISSION:
      return 'Subscription Mgt';
    case ADMIN_EDIT_COMPANY_INFO_PERMISSION:
      return 'Company Mgt';
    case ADMIN_MANAGE_BILLING_PERMISSION:
      return 'Billing Mgt';
    // Practitioner permissions
    case PRACTITIONER_REGISTER_EVENT_PERMISSION:
      return 'Event Registration';
    // Employee permissions
    case EMPLOYEE_LEADER_PERMISSION:
      return 'Leader Role';
    default:
      return 'Unknown permission';
  }
};

/**
 * Company Admin permission mgt
 */

/**
 * Returns the default company admins permissions
 * @returns {number} default company admins permissions set
 */
export const getCompanyAdminsDefaultPermissions = () => {
  return ADMIN_DEFAULT_PERMISSIONS;
};

/**
 * from global user permissions property return only the permissions that apply
 * to the Admin company role
 * @param {number} permissions - permissions to get admin only permissions from
 * @returns {number} user admin only granted permissions
 */
const getAdminOnlyPermissions = (permissions) => permissions & ALL_ADMIN_PERMISSIONS;

export const isSuperAdmin = (user) => {
  return isCompanyAdmin(user) && arePermissionsSet(user, SUPER_ADMIN_PERMISSIONS);
};

// to be able to manage the beneficiaries list, following conditions need to be met:
// user is VB admin or
// user has company admin role
// AND company has beneficiary management permission(s)
// AND company admin has manage beneficiary permission
export const canManageEmployee = (user) =>
  isViabeezAdmin(user) ||
  (isCompanyAdmin(user) &&
    isAccountsManagedCompany(user?.company) &&
    isPermissionSet(user, ADMIN_MANAGE_EMPLOYEE_PERMISSION));

export const canManageEvent = (user) => isCompanyAdmin(user) && isPermissionSet(user, ADMIN_MANAGE_EVENT_PERMISSION);

export const canViewNotification = (user) =>
  isCompanyAdmin(user) && isPermissionSet(user, ADMIN_MANAGE_VIEW_NOTIFICATION_PERMISSION);

export const canViewStats = (user) => isCompanyAdmin(user) && isPermissionSet(user, ADMIN_MANAGE_VIEW_STATS_PERMISSION);

// NOTE: for now Viabeez Admin also allowed as he can change admins permissions
export const canManageAdmin = (user) =>
  isViabeezAdmin(user) || (isCompanyAdmin(user) && isPermissionSet(user, ADMIN_MANAGE_ADMIN_PERMISSION));

export const canManageSite = (user) => isCompanyAdmin(user) && isPermissionSet(user, ADMIN_MANAGE_SITE_PERMISSION);

export const canManageSubscription = (user) =>
  isCompanyAdmin(user) && isPermissionSet(user, ADMIN_MANAGE_SUBSCRIPTION_PERMISSION);

export const canManageCompany = (user) =>
  isCompanyAdmin(user) && isPermissionSet(user, ADMIN_EDIT_COMPANY_INFO_PERMISSION);

export const canManageBilling = (user) =>
  isCompanyAdmin(user) && isPermissionSet(user, ADMIN_MANAGE_BILLING_PERMISSION);

/**
 * Updates an administrator permission
 * Caller must be granted ADMIN_MANAGE_ADMIN_PERMISSION permission
 * @param {Object} caller - User requesting permission change
 * @param {Object} user - Admin user to update
 * @param {number} permission - permission to manage
 * @param {boolean} [set=true] - action to perform, if true (default) sets the permission, otherwise resets permission
 * @returns {boolean} true if operation was successful
 */
export const updateAdminPermission = async (caller, user, permission, set = true) => {
  if (canManageAdmin(caller)) {
    if (isCompanyAdmin(user)) {
      const ret = set ? await setUserPermission(user, permission) : await unsetUserPermission(user, permission);
      console.log(
        `Permissions::updateAdminPermission: user Id ${user?._id} permission ${getPermissionLabel(permission)} ${
          set ? 'set' : 'reset'
        } returned ${ret}`
      );
      return ret;
    } else {
      console.error(`Permissions::updateAdminPermission: Error user Id ${user?._id} is not an administrator`);
      return false;
    }
  } else {
    console.error(
      `Permissions::updateAdminPermission: Error caller (Id ${caller?._id}) is not allowed to perform action`
    );
    return false;
  }
};

/**
 * Practitioner permission mgt
 * this permission is true by default for practitioners
 * and only set to false server side in specific case when a provider practitioner account gets created
 */
export const canRegisterEvent = (user) =>
  isPractitioner(user) && isPermissionSet(user, PRACTITIONER_REGISTER_EVENT_PERMISSION);

/**
 * Practitioner permission mgt
 * this permission is true by default for practitioners
 * the price can actually be modified only if specialty has customPrice = true
 */
export const canModifyAppointmentPrice = (user) =>
  isPractitioner(user) &&
  isPermissionSet(user, PRACTITIONER_MODIFY_PRICE_PERMISSION) &&
  !!user?.specialty &&
  !!user.specialty?.customPrice;

//
// NOTE: Leader APIs below assume a company admin who is also an employee can also be a leader if granted that role
//

/**
 * Employee permission mgt
 * for now this code considers that as a company admin
 * can also act as an employee, the company admin might
 * be assigned the leader role as well
 */

/**
 * from global user permissions property return only the permissions that apply
 * to the employee role
 * @param {number} perm - permissions to get employee only permissions from
 * @returns {number} user employee only granted permissions
 */
export const getEmployeeOnlyPermissions = (perm) => perm & ALL_EMPLOYEE_PERMISSIONS;

export const isLeader = (user) =>
  (isEmployee(user) || isCompanyAdmin(user)) && isPermissionSet(user, EMPLOYEE_LEADER_PERMISSION);

/**
 * Performs employee promotion to leader
 * Caller must be granted ADMIN_MANAGE_EMPLOYEE_PERMISSION permission
 * @param {Object} caller - User requesting permission change
 * @param {Object} user - User to promote to leader
 * @returns {boolean} true if operation was successful
 */
export const promoteToLeader = (caller, user) => {
  if (canManageEmployee(caller)) {
    if (isEmployee(user) || isCompanyAdmin(user)) {
      if (!isLeader(user)) {
        return setUserPermission(user, EMPLOYEE_LEADER_PERMISSION);
      } else {
        console.error(`Permissions::promoteToLeader: Error user Id ${user?._id} already has leader role`);
      }
    } else {
      console.error(`Permissions::promoteToLeader: Error user Id ${user?._id} is not an employee`);
    }
  } else {
    console.error(`Permissions::promoteToLeader: Error caller (Id ${caller?._id}) is not allowed to perform action`);
  }
  return false;
};

/**
 * Revokes employee leader role
 * Caller must be granted ADMIN_MANAGE_EMPLOYEE_PERMISSION permission
 * @param {Object} caller - User requesting permission change
 * @param {Object} user - User to revoke
 * @returns {boolean} true if operation was successful
 */
export const revokeLeaderRole = (caller, user) => {
  if (canManageEmployee(caller)) {
    if (isEmployee(user) || isCompanyAdmin(user)) {
      if (isLeader(user)) {
        return unsetUserPermission(user, EMPLOYEE_LEADER_PERMISSION);
      } else {
        console.error(`Permissions::revokeLeaderRole: Error user Id ${user?._id} has NO leader role`);
      }
    } else {
      console.error(`Permissions::revokeLeaderRole: Error user Id ${user?._id} is not an employee`);
    }
  } else {
    console.error(`Permissions::revokeLeaderRole: Error caller (Id ${caller?._id}) is not allowed to perform action`);
  }
  return false;
};

export const requestAdminPermissionsChange = async (caller, user, permissions) => {
  if (canManageAdmin(caller)) {
    if (isCompanyAdmin(user)) {
      // warn if current user permission doesn't seem valid
      if (!isCompanyAdminPermission(permissions)) {
        console.warn(`requestAdminPermissionsChange:: Permission ${permissions} is not a valid Admin permission value`);
      }
      const userUpdate = { ...user, permissions };
      return await updateUserPermission(userUpdate);
    } else {
      console.error(`requestAdminPermissionsChange:: user id: ${user._id} is not a company admin`);
    }
  } else {
    console.error(`requestAdminPermissionsChange:: user id: ${caller._id} is not allowed to manage administrators`);
  }
};

/**
 * sets a permission on a user
 * @param {Object} user - User to set permission for
 * @param {Number} permission - permission to set (expected to be a single permission at one time)
 * @returns {boolean} true if operation was successful
 */
const setUserPermission = async (user, permission) => {
  // can't change root admin permissions
  if (isCompanyRootAdmin(user)) {
    console.error("setUserPermission:: Error, can't change root admin permissions");
    return false;
  }

  const { role } = user;
  let validPerm;
  switch (role) {
    // if user is admin both admin & employee permissions are valid
    case UserRole.adminCompany:
      validPerm = isCompanyAdminPermission(permission) || isEmployeePermission(permission);
      break;
    case UserRole.practitioner:
      validPerm = isPractitionerPermission(permission);
      break;
    default:
      validPerm = isEmployeePermission(permission);
  }
  if (validPerm) {
    console.log(
      `setUserPermission: setting permission "${getPermissionLabel(permission)}" for user id: ${
        user?._id
      } - role: ${role}`
    );
    const userUpdate = { ...user };
    let userPermissions = user?.permissions || 0;
    userPermissions |= permission;
    userUpdate.permissions = userPermissions;
    return await updateUserPermission(userUpdate, isEmployeePermission(permission));
  } else {
    console.error(`setUserPermission:: Permission ${permission} is not valid for user role: ${role}`);
  }
  return false;
};

/**
 * resets a permission on a user
 * @param {Object} user - User to reset permission for
 * @param {Number} permission - permission to reset (expected to be a single permission at one time)
 * @returns {boolean} true if operation was successful
 */
const unsetUserPermission = async (user, permission) => {
  // can't change root admin permissions
  if (isCompanyRootAdmin(user)) {
    console.error("unsetUserPermission:: Error, can't change root admin permissions");
    return false;
  }

  const { role } = user;
  let validPerm;
  switch (role) {
    case UserRole.adminCompany:
      validPerm = isCompanyAdminPermission(permission) || isEmployeePermission(permission);
      break;
    case UserRole.practitioner:
      validPerm = isPractitionerPermission(permission);
      break;
    default:
      validPerm = isEmployeePermission(permission);
  }
  if (validPerm) {
    console.log(
      `setUserPermission: resetting permission "${getPermissionLabel(permission)}" for user id: ${
        user?._id
      } - role: ${role}`
    );
    const userUpdate = { ...user };
    let userPermissions = user?.permissions || 0;
    userPermissions &= ~permission;
    userUpdate.permissions = userPermissions;
    return await updateUserPermission(userUpdate, isEmployeePermission(permission));
  } else {
    console.error(`unsetUserPermission:: Permission ${permission} is not valid for user role: ${role}`);
  }
  return false;
};

/**
 * code to call server API in order to update permissions field on given user
 * @param {Object} user - User to update permissions prop for
 * @returns {boolean} true if operation was successful
 */
const updateUserPermission = async (user, forceEmployeeApi = false) => {
  // can't change root admin permissions
  if (isCompanyRootAdmin(user)) {
    console.error("updateUserPermission:: Error, can't change root admin permissions");
    return false;
  }

  const { _id, permissions, role } = user;
  let url;
  if (!_id) {
    console.error(`updateUserPermission:: Error, user has NO _id property`);
    return false;
  }
  if (permissions != null) {
    if (!role) {
      console.error(`updateUserPermission:: Error, user ${_id} has NO role property`);
      return false;
    }
    switch (role) {
      case UserRole.adminCompany:
        // if we are setting an employee permission on admin user
        // force employee API as admin API requires additional admin mgt permission
        if (forceEmployeeApi) {
          url = `/users/${_id}/employee-rights`;
        } else {
          url = `/users/${_id}/admin-rights`;
        }
        break;
      case UserRole.employee:
        url = `/users/${_id}/employee-rights`;
        break;
      default:
      //TODO: NO processing today for practitioner permissions
    }
    if (url) {
      console.log(`updateUserPermission:: Calling API: ${url} to update permission for user ${_id}`);
      const ret = await api.put(url, { permissions });
      console.log(`updateUserPermission:: API returned status: ${ret?.status}/${ret?.statusText}`);
      return ret?.data?.status ? ret.data.status === 'success' : false;
    }
    return true;
  } else {
    console.error(`updateUserPermission:: Error, user ${_id} has NO permissions property`);
    return false;
  }
};

// DEBUG LOG

export const logAdminPermissions = (user, permissionsToLog = null) => {
  const { permissions, company } = user;
  if (!isCompanyAdmin(user)) {
    console.error('logAdminPermissions:: User is not a company admin!');
    return;
  }
  const userPermissions = permissionsToLog || permissions;
  let index = 0,
    perm;
  const maxPerm = 1 << 8;
  if (typeof userPermissions === 'number') {
    console.log(`----- A D M I N   P E R M I S S I O N S // Value : ${userPermissions} `.padEnd(73, '-'));
    if ((userPermissions & SUPER_ADMIN_PERMISSIONS) === SUPER_ADMIN_PERMISSIONS) {
      console.log(''.padEnd(73, '-'));
      console.log('User is a super administrator (ALL permissions)');
    } else {
      console.log('| BENEF | EVENT | NOTIF | STATS | ADMIN | SITES |  SUB  | INFO  | BILL  |');
      console.log(''.padEnd(73, '-'));
      let output = '|';
      do {
        perm = 1 << index++;
        output += userPermissions & perm ? '   X   |' : '       |';
      } while (perm < maxPerm);
      console.log(output);
    }
    if (!isAccountsManagedCompany(company)) {
      console.log('logAdminPermissions:: NO beneficiary mgt permission at company level!');
    }
    console.log(''.padEnd(73, '-'));
  } else {
    console.error('logAdminPermissions:: NO permissions provided!');
  }
};

/**
 * determines what permission profile the admin user has
 * @param {Object} user - User to check for admin profile for
 * @returns {string} the admin permission profile label
 */
export const getPermissionsProfileLabelId = (user) => {
  const { permissions, _id: userId } = user ?? {};
  if (permissions == null) {
    return '';
  }
  if (!isCompanyAdmin(user)) {
    console.error(`getPermissionsProfileLabelId: user ${userId} is not a company administrator`);
    return '';
  }
  return getAdminPermsProfileId(permissions);
};

export const getAdminPermsProfileId = (permissions) => {
  // filter out possible employee permissions
  const perm = getAdminOnlyPermissions(permissions);
  switch (perm) {
    case SUPER_ADMIN_PERMISSIONS:
      return 'admin.permission.profile.super.admin';
    case COMPANY_MANAGER_PERMISSIONS:
      return 'admin.permission.profile.manager';
    case COMPANY_OPERATOR_PERMISSIONS:
      return 'admin.permission.profile.operator';
    default:
      return 'admin.permission.profile.custom';
  }
};

export const PERMISSIONS_PROFILES = [
  {
    nameId: 'admin.permission.profile.super.admin',
    permissions: SUPER_ADMIN_PERMISSIONS,
    descriptionIds: ['admin.permissions.all.description']
  },
  {
    nameId: 'admin.permission.profile.manager',
    permissions: COMPANY_MANAGER_PERMISSIONS,
    descriptionIds: [
      'admin.permissions.events.description',
      'admin.permissions.beneficiaries.description',
      'admin.permissions.dashboard.description',
      'admin.permissions.admins.description',
      'admin.permissions.sites.description'
    ]
  },
  {
    nameId: 'admin.permission.profile.operator',
    permissions: COMPANY_OPERATOR_PERMISSIONS,
    descriptionIds: [
      'admin.permissions.events.description',
      'admin.permissions.beneficiaries.description',
      'admin.permissions.dashboard.description'
    ]
  },
  {
    nameId: 'admin.permission.profile.custom',
    permissions: ADMIN_DEFAULT_PERMISSIONS
  }
];

export const getPermissionsProfileIndex = (user) => {
  if (user?.permissions == null) {
    return -1;
  }
  switch (user.permissions) {
    case SUPER_ADMIN_PERMISSIONS:
      return 0;
    case COMPANY_MANAGER_PERMISSIONS:
      return 1;
    case COMPANY_OPERATOR_PERMISSIONS:
      return 2;
    default:
      return 3;
  }
};

/**
 * Checks if edit of a specific permission bitmask is allowed
 * for given user
 * for now only case is
 *    perm = manage sites AND user currently manages sites
 *    in this case, manage sites perm can't be modified
 *
 * @param {number} permBitmask - value of perm to check
 * @param {Object} user - user for whom permission check applies
 * @returns {boolean} true is this permission edit should be disabled
 */
export const isDisabledPermission = (permBitmask, user) => {
  if (permBitmask === ADMIN_MANAGE_SITE_PERMISSION) {
    if (user && managesSites(user)) {
      return true;
    }
  }
  return false;
};

/**
 * Checks if selection of a given permission profile is allowed
 * for given user
 * for now only case is
 *    profile = operator AND user currently manages sites
 *    in this case, profile can't be selected as operator profile doesn't include
 *    sites mgt
 *
 * @param {string} profileNameId - name Id of profile to check
 * @param {Object} user - user for whom permission check applies
 * @returns {boolean} true is this profile should be disabled
 */
export const isDisabledProfile = (profileNameId, user) => {
  if (profileNameId === 'admin.permission.profile.operator') {
    if (user && managesSites(user)) {
      return true;
    }
  }
  return false;
};
