import { useTranslation } from 'react-i18next';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { phoneFormat } from 'src/components/Card/cardData';
import {
  getEventCommission,
  getEventPractitioner,
  getPractitionerName,
  getPractitionerTitleLabel,
  parseRevenue
} from 'src/utility/practitionerUtils';
import { specialtyGeneratesRevenue } from '../../../features/specialties/specialtiesUtils';
import ItemsTable from './ItemsTable';

const styles = StyleSheet.create({
  imageContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  image: {
    width: '40%'
  },
  body: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 16,
    marginTop: 12,
    borderBottom: '1 solid #000'
  },
  errorTitle: {
    fontSize: 18,
    marginTop: 12,
    color: 'red',
    textAlign: 'center'
  },
  subtitle: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 14,
    marginTop: 12,
    fontWeight: 'bold'
  },
  subtitle2: {
    fontFamily: 'Helvetica-Oblique',
    fontSize: 12,
    marginTop: 3,
    fontStyle: 'italic'
  },
  cardSubtitle: {
    fontSize: 14,
    marginBottom: 12,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold'
  },
  text: {
    fontSize: 14
  },
  reasonText: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10
  },
  card: {
    border: '2 solid #000',
    marginTop: 12,
    marginBottom: 12,
    padding: 5
  }
});

const ErrorDocument = ({ reason }) => {
  const { t } = useTranslation();
  const { body, imageContainer, image, errorTitle, reasonText } = styles;
  return (
    <Document>
      <Page style={body} wrap>
        <View style={imageContainer}>
          <Image style={image} src="/Viabeez_pdf.jpg" />
        </View>
        <Text style={errorTitle}>{t('pdf.events.analytics.error.title')}</Text>
        <Text style={reasonText}>{reason}</Text>
      </Page>
    </Document>
  );
};

const AnalyticsDocument = ({ tableData, event, registrationCount, totalRevenue, formattedRevenue }) => {
  const { t } = useTranslation();
  if (!event) {
    console.warn(`AnalyticsDocument: NO event data provided!`);
    return <ErrorDocument reason={t('pdf.events.analytics.error.missing.event')} />;
  }
  const { email, phone } = getEventPractitioner(event);
  const { specialty, dateStart, timeStart, timeEnd, company, practitioner, groupSessionEvent } = event;

  if (!practitioner) {
    console.warn(`AnalyticsDocument: Event ${event?._id} has no practitioner data!`);
    return <ErrorDocument reason={t('pdf.events.analytics.error.missing.partner')} />;
  }

  const {
    name = t('common.not.available.label'),
    address: { street = '', city = t('common.not.available.label'), zipcode = '' } = {}
  } = company ?? {};
  const { label } = specialty;
  const practitionerName = getPractitionerName(event);
  const eventCommission = getEventCommission(event);
  const eventHasRevenueInfo = specialtyGeneratesRevenue(specialty);
  const { body, imageContainer, image, title, subtitle, subtitle2, text, card, cardSubtitle } = styles;

  return (
    <Document>
      <Page style={body} wrap>
        <View style={imageContainer}>
          <Image style={image} src="/Viabeez_pdf.jpg" />
        </View>
        <Text style={title}>
          {t('pdf.events.analytics.edit.title', { editDate: moment().tz('UTC').format('DD/MM/YYYY') })}
        </Text>
        <Text style={subtitle}>
          {t('pdf.events.analytics.event.date', {
            eventSpecialty: label,
            eventDate: moment(dateStart).tz('UTC').format('DD/MM/YYYY'),
            start: timeStart,
            end: timeEnd
          })}
        </Text>
        {groupSessionEvent && <Text style={subtitle2}>{t('pdf.events.analytics.group.session.event')}</Text>}
        <Text style={subtitle}>{t('pdf.events.analytics.company.title')}</Text>
        <Text style={text}>{name}</Text>
        <Text style={text}>
          {street}, {zipcode} {city}
        </Text>
        <View>
          <Text style={subtitle}>
            {groupSessionEvent ? t('pdf.events.analytics.speaker.title') : t('pdf.events.analytics.practitioner.title')}
          </Text>
          <Text style={text}>{practitionerName}</Text>
          <Text style={text}>{getPractitionerTitleLabel(groupSessionEvent ? specialty : practitioner?.specialty)}</Text>
          <Text style={text}>
            {email ? email : `${t('common.label.short.email')} : ${t('common.not.provided.male.label')}`}
          </Text>
          <Text style={text}>
            {phone ? phoneFormat(phone) : `${t('common.label.short.phone')} : ${t('common.not.provided.male.label')}`}
          </Text>
        </View>
        <View style={card}>
          <Text style={cardSubtitle}>{t('pdf.events.analytics.summary.title')}</Text>
          <Text style={text}>
            {groupSessionEvent
              ? t('event.attendees.count', { count: registrationCount })
              : t('event.appointments.count', { count: registrationCount })}
          </Text>
          {eventHasRevenueInfo && (
            <>
              <Text style={text}>{t('component.event.card.total.revenue.verbose', { formattedRevenue })}</Text>
              <Text style={text}>
                {t('event.commission.applicable')}
                {eventCommission !== '' ? ` ${eventCommission}%` : t('common.not.defined.male.label')}
              </Text>
              {totalRevenue && eventCommission && (
                <Text style={text}>
                  {t('event.practitioner.commission.amount')}
                  {` ${parseRevenue(totalRevenue * (eventCommission / 100))}`}
                </Text>
              )}
            </>
          )}
        </View>
        {tableData.length > 0 && (
          <ItemsTable
            groupSessionEvent={groupSessionEvent}
            tableData={tableData}
            eventHasRevenueInfo={eventHasRevenueInfo}
          />
        )}
      </Page>
    </Document>
  );
};

export default AnalyticsDocument;
